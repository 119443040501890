import { gql, Query } from 'overmind-graphql';

import { AddRecordBookEntry, AddRecordBookEntryVariables } from './graphql-types/AddRecordBookEntry';
import { ProcessRecordBookEntry, ProcessRecordBookEntryVariables } from './graphql-types/ProcessRecordBookEntry';
export const addRecordBookEntry: Query<AddRecordBookEntry, AddRecordBookEntryVariables> = gql`
  mutation AddRecordBookEntry($rbe: offline_SyncTransaction_insert_input!) {
    insert_offline_SyncTransaction_one(object: $rbe) {
      Id
      SyncDetails {
        Id
      }
    }
  }
`;

export const processRecordBookEntry: Query<ProcessRecordBookEntry, ProcessRecordBookEntryVariables> = gql`
  mutation ProcessRecordBookEntry($id: uuid) {
    update_offline_SyncTransaction(where: { Id: { _eq: $id } }, _set: { Status: "pending" }) {
      affected_rows
    }
  }
`;
