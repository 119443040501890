import React from 'react';

import { TRecordBookEntry } from '../../overmind/state';
import { ConflictMap, RecordBookEntriesProps } from './RecordBookEntries';
import RecordBookEntryCard from './RecordBookEntryCard';

const RecordBookEntriesList = ({
  records,
  isOnline,
  expandable = true,
  onSync: syncRecord,
  onEdit: editRecord,
  onDelete: deleteRecord,
  onAttach: openAttachments,
  onDeleteAttachment: deleteAttachment,
  showConflictChildren = true,
}: RecordBookEntriesProps) => {
  const [conflictIds, setConflictIds] = React.useState<ConflictMap>({});
  const [parents, setParents] = React.useState<TRecordBookEntry[]>([]);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Here wer work out which records are in conflict and we then arrange is a parent-child
    // like relationship to allow child records to be collapsible under their respective
    // parent.
    const conflictRecords: ConflictMap = {};

    records.map((record) => {
      if (record.conflictId) {
        if (record.conflictId in conflictRecords) {
          conflictRecords[record.conflictId].push(record.id!); // add to array
        } else {
          conflictRecords[record.conflictId] = [record.id!]; // create array
        }
      }
    });

    setConflictIds(conflictRecords);

    if (showConflictChildren) {
      // records prop contains all valid records and records that have 1 or more conflict
      // records in conflict with it.
      setParents(records.filter((rec) => rec.conflictId === null));
    } else {
      // records prop contains just conflict records
      setParents(records);
    }
  }, [records]);

  const handleSync = (index: number) => {
    if (syncRecord && typeof syncRecord === 'function') return syncRecord(parents[index]);
  };

  const handleEdit = (index: number) => {
    // Note: this index refers to the "parents" array and therefore must be adjusted
    // to correctly point to same record in the root array: records if an index is to
    // be passed.
    if (editRecord && typeof editRecord === 'function') return editRecord(parents[index]);
  };

  const handleDelete = (index: number) => {
    console.log('delete here', { index, records, parents, parent: parents[index] });
    // Note: this index refers to the "parents" array and therefore must be adjusted
    // to correctly point to same record in the root array: records if an index is to
    // be passed.
    if (deleteRecord && typeof deleteRecord === 'function') return deleteRecord(parents[index]);
  };

  const handleAttachments = (index: number) => {
    if (openAttachments && typeof openAttachments === 'function') {
      return openAttachments(parents[index]);
    }
  };

  const handleDeleteAttachment = (index: number) => (filename: string) => {
    console.log({ type: typeof deleteAttachment, index, filename });
    if (deleteAttachment && typeof deleteAttachment === 'function') {
      deleteAttachment(parents[index], filename);
      setIsDirty(!isDirty); // This is a hack to force a rerender in order to display altered documents list.
    }
  };

  if (!records || records.length === 0) {
    return <div>There are no record book entries to show</div>;
  }

  return (
    <>
      {parents.map((record, index) => {
        const isConflict = record.id! in conflictIds;
        const duplicates =
          record.id! in conflictIds ? records.filter((rec) => conflictIds[record.id!].includes(rec.id!)) : [];

        return (
          <RecordBookEntryCard
            key={record.id!}
            record={record}
            isConflict={isConflict}
            duplicates={duplicates}
            isOnline={isOnline}
            onEdit={() => handleEdit(index)}
            onSync={() => handleSync(index)}
            onAttach={() => handleAttachments(index)}
            onDelete={() => handleDelete(index)}
            onDeleteAttachment={handleDeleteAttachment(index)}
          />
        );
      })}
    </>
  );
};

export default RecordBookEntriesList;
