import React, { useEffect } from 'react';

import { format } from 'date-fns';
import { Route, Switch, Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import SyncIcon from '@material-ui/icons/Sync';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import AddIcon from '@material-ui/icons/Add';

import { green, grey, red } from '@material-ui/core/colors';

import VesselList from '../VesselList';
import AntiFouling from '../AntiFouling';
import RecordBook from '../RecordBook';
import HomePage from '../Homepage';
import Logout from '../Logout';
import { useActions, useState } from '../../overmind';
import ManagementPlanning from '../ManagementPlanning';
import Toolbar from '@material-ui/core/Toolbar';

const VESSEL_IMAGE_URL = process.env.REACT_APP_VESSEL_IMAGES_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    around: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  }),
);

const Homepage: React.FC = () => {
  const classes = useStyles();
  const {
    vesselInfo: { fetchUserVesselData, fetchOfflineRecordEntryData },
    vesselRecords: { saveRecordBookEntries },
  } = useActions();

  useEffect(() => {
    // online data retrieval
    fetchUserVesselData(); // data fetched from bioflouing schema
    fetchOfflineRecordEntryData(); // data fetched from offline schema
  }, [fetchUserVesselData, fetchOfflineRecordEntryData]);

  const HeaderFrontPage: React.FC = () => {
    const {
      app: { networkStatus },
    } = useState();

    const isOnline = networkStatus.toLowerCase() === 'online';

    const refresh = () => {
      fetchUserVesselData(); // data fetched from bioflouing schema
      fetchOfflineRecordEntryData();
      saveRecordBookEntries();
    };

    return (
      <Box
        bgcolor="white"
        px={4}
        position="sticky"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        top="4.75rem"
        zIndex={9}
        borderBottom={`1px solid ${grey[300]}`}
      >
        <Tabs value={0} indicatorColor="primary" textColor="primary" aria-label="disabled tabs example">
          <Tab label="Fleet" />
        </Tabs>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            disabled={!isOnline}
            onClick={refresh}
            startIcon={
              isOnline ? (
                <SyncIcon style={{ color: green[400] }} fontSize="small" />
              ) : (
                <SyncDisabledIcon style={{ color: grey[400] }} fontSize="small" />
              )
            }
          >
            Refresh All
          </Button>
        </Box>
      </Box>
    );
  };

  const Header: React.FC = () => {
    let params: any = useParams();

    let { pathname, search } = useLocation();
    const {
      vessels: { selectedVessel },
      app: { networkStatus, isSync },
    } = useState();
    const vesselId = selectedVessel?.VesselId;
    const vessel = selectedVessel?.VesselParticular;
    const hasImage = search.indexOf('?ni') === -1;
    const vesselImage = hasImage ? `url(${VESSEL_IMAGE_URL}/${vesselId}/display.jpg)` : 'url(/no-image.jpg)';

    return (
      <AppBar position="fixed" style={{ zIndex: 10 }}>
        <Box bgcolor="white" px={4} py={1} mt={8} justifyContent="flex-start" borderBottom={`1px solid ${grey[100]}`}>
          <Box
            flex="auto"
            display="flex"
            border={`1px solid ${grey[300]}`}
            p={1}
            borderRadius={8}
            className={classes.around}
            justifyContent="space"
          >
            <Box mr={1}>
              <Button color="primary" startIcon={<KeyboardBackspaceIcon fontSize="small" />} component={Link} to="/">
                Back to Fleet
              </Button>
            </Box>
            <Box flexDirection="row" display="flex">
              <Box
                width="10rem"
                height="5rem"
                mr={1}
                style={{
                  borderRadius: '0.5rem',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: vesselImage,
                }}
              />

              <Box display="flex" flexDirection="column">
                <Typography variant="h6" color="textPrimary">
                  {vessel?.VesselName}
                </Typography>
                <Box display="flex">
                  <Typography style={{ marginRight: '0.75rem' }} variant="body2" color="textPrimary">
                    IMO: {vessel?.IMONumber}
                  </Typography>
                  <Typography style={{ marginRight: '0.75rem' }} variant="body2" color="textPrimary">
                    Callsign: {vessel?.CallSign}
                  </Typography>
                  <Typography style={{ marginRight: '0.75rem' }} variant="body2" color="textPrimary">
                    DWT: {vessel?.DWT}
                  </Typography>
                </Box>
                <div className={classes.grow} />
                <Box display="flex" alignItems="center" mt={0.5}>
                  {networkStatus.toLowerCase() === 'offline' ? (
                    <Tooltip title="Offline" placement="right">
                      <FiberManualRecordIcon fontSize="small" style={{ color: red[600], marginRight: '0.25rem' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Online" placement="right">
                      <FiberManualRecordIcon fontSize="small" style={{ color: green[300], marginRight: '0.25rem' }} />
                    </Tooltip>
                  )}
                  <Typography variant="caption" color="textPrimary">
                    Last Synced on {format(new Date(), 'dd/M/yyyy hh:mm a')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <div className={classes.grow}></div>
            {/* <Box>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => {
                  // setDialogIsOpen(!dialogIsOpen);
                }}
              >
                ADD RECORD
              </Button>
            </Box> */}
          </Box>
        </Box>
        <Box bgcolor="white" px={4} position="sticky" top="4.75rem" zIndex={9} borderBottom={`1px solid ${grey[300]}`}>
          <Tabs value={pathname} variant="fullWidth" indicatorColor="primary" textColor="primary" centered>
            <Tab
              label="Biofouling Record Book"
              value={`/recordbook/${params.id}`}
              component={Link}
              to={`/recordbook/${params.id}`}
            />
            <Tab
              label="AFC Documentation"
              value={`/antifouling/${params.id}`}
              component={Link}
              to={`/antifouling/${params.id}`}
            />
            <Tab
              label="Management Planning"
              value={`/managementPlanning/${params.id}`}
              component={Link}
              to={`/managementPlanning/${params.id}`}
            />
          </Tabs>
        </Box>
      </AppBar>
    );
  };

  return (
    <Switch>
      <Route path="/antifouling/:id">
        <Header />
        <AntiFouling />
      </Route>
      <Route path="/recordbook/:id">
        <Header />
        <RecordBook />
      </Route>
      <Route path="/managementPlanning/:id">
        <Header />
        <ManagementPlanning />
      </Route>
      <Route path="/home">
        <HeaderFrontPage />
        <HomePage />
      </Route>
      <Route path="/logout">
        <HeaderFrontPage />
        <Logout />
      </Route>
      <Route path="/">
        <HeaderFrontPage />
        <VesselList />
      </Route>
    </Switch>
  );
};

export default Homepage;
