import { derived } from 'overmind';
import DbManager from './effects/storage/DBManager';
import {
  fetchUserData_User_VesselParticularsUsers as VesselParticularsUsers,
  fetchUserData_Port,
  fetchUserData_User,
  fetchUserData_ActionsNiches,
  fetchUserData_ActionsNiches_NicheAreaDefinition as NicheAreaDefinition,
  fetchUserData_ActionsNiches_VesselsBMRecordType as VesselsBMRecordType,
  fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselsBMRecords_VesselBMRecordDocuments as VesselBMRecordDocuments,
} from './effects/gql/graphql-types/fetchUserData';
import { flattenBy, getCurrentVesselRecords } from './utils';

export type TFileAttachment = {
  id: string;
  vesselid: number;
  filename: string;
  filetype: string;
  filesize: number;
  file: ArrayBuffer;
};

export type TUserDetails = {
  expires_in: number;
  scope: string;
  token: string;
  token_type: 'bearer' | null;
  user: string;
};

export type TUser = {
  user: TUserDetails;
  userId: string;
  status: number;
};

export type TActionNiches = {
  action: VesselsBMRecordType;
  niches: NicheAreaDefinition[];
};

export class ActionNiches {
  action: VesselsBMRecordType | null;
  niches: NicheAreaDefinition[];
  constructor(action: VesselsBMRecordType | null = null, niches: NicheAreaDefinition[] = []) {
    this.action = action;
    this.niches = niches;
  }
}

export class NicheActions {
  niche: NicheAreaDefinition | null;
  actions: VesselsBMRecordType[];
  constructor(niche: NicheAreaDefinition | null = null, actions: VesselsBMRecordType[] = []) {
    this.niche = niche;
    this.actions = actions;
  }
}

export type TNicheActions = {
  niche: NicheAreaDefinition;
  actions: VesselsBMRecordType[];
};
export type TVessels = {
  name: string | null;
  userVesselData: fetchUserData_User | null;
  selectedVesselId: number | null;
  selectedVessel: VesselParticularsUsers | undefined | null;
  selectedVesselActionNiches: Record<string, ActionNiches>;
};

export type PortDetail = {
  CountryCode: string | null;
  PortCode: string | null;
  PortName: string | null;
};

// export type TNiches = Array<{
//   value: number | string;
//   label: string | undefined | null;
//   subLabel?: string | null;
//   vesselAfsMaintenanceActionId?: number;
// }>;

export type TNiches = Array<NicheAreaDefinition>;

export type TActions = Array<{
  value: number | string | undefined;
  label: string | null | undefined;
  niches: TNiches;
}>;

export type TAuth = {
  error: any;
  currentUser: TUser | null;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
};
export type TApp = {
  isSync: boolean;
  isOnline: boolean;
  networkStatus: string;
};

export type TRecordAdHoc = {
  isAdHoc: boolean;
};

export type TRecordSync = 'synced' | 'notsynced' | 'pending';
export type TRecordBookEntry = {
  id: number | null;
  vesselId: number | null;
  newRecord: boolean;
  synced: 'synced' | 'notsynced' | 'pending';
  txId: string | null;
  cleaningInspectionDate: Date;
  location: string;
  isAdHoc: boolean;
  description: string | null;
  responsiblePerson: string;
  managementActionId: number;
  managementAction: string;
  conflictId: number | null;
  deletedAt: Date | null;
  vesselNiches: Array<NicheAreaDefinition>;
  documents: Array<VesselBMRecordDocuments>;
  deleted: boolean;
  lastUpdatedAt: Date | null;
};

export type TAppState = {
  ports: PortDetail[];
  vessels: TVessels;
  mgmtActionsNicheAreas: fetchUserData_ActionsNiches[];
  nicheActions: Record<string, NicheActions>;
  actionNiches: Record<string, ActionNiches>;
  recordBookEntries: TRecordBookEntry[];
  selectedVesselRecordBookEntries: TRecordBookEntry[];
  auth: TAuth;
  app: TApp;
  iDb: DbManager | null;
};
export const state: TAppState = {
  actionNiches: derived((state: TAppState) => flattenBy(state.mgmtActionsNicheAreas)('action') as any),
  nicheActions: derived((state: TAppState) => flattenBy(state.mgmtActionsNicheAreas)('niche') as any),
  ports: [],
  mgmtActionsNicheAreas: [],
  vessels: {
    name: null,
    userVesselData: null,
    selectedVesselId: null,
    selectedVessel: derived((vessels: TVessels) => {
      let vessel: VesselParticularsUsers | undefined | null = null;
      const vesselId = vessels.selectedVesselId;
      if (vesselId) {
        vessel = vessels.userVesselData?.VesselParticularsUsers.filter((v) => v.VesselId === vesselId)[0];
      }
      return vessel;
    }),
    selectedVesselActionNiches: derived(
      (user: TVessels, rootState: TAppState): Record<string, ActionNiches> => {
        let vessel: VesselParticularsUsers | undefined | null = null;
        const vesselId = user.selectedVesselId;
        if (vesselId) {
          vessel = user.userVesselData?.VesselParticularsUsers.filter((v) => v.VesselId === vesselId)[0];
          const vesselNiches = vessel?.VesselParticular.VesselNiches;
          const vesselNicheSet = new Set<number>();
          const vesselActionNiches: Record<string, ActionNiches> = {};
          vesselNiches?.map((vesselNiche) => {
            vesselNicheSet.add(vesselNiche.NicheAreaDefinition?.Id);
          });
          for (const actionId in rootState.actionNiches) {
            vesselActionNiches[actionId] = {
              action: {
                ...(rootState.actionNiches[actionId].action as VesselsBMRecordType),
              },
              niches: [],
            };
            for (const niche of rootState.actionNiches[actionId].niches) {
              if (vesselNicheSet.has(niche.Id)) {
                vesselActionNiches[actionId].niches.push({ ...niche });
              }
            }
          }
          return vesselActionNiches;
        }
        return {};
      },
    ),
  },
  recordBookEntries: [], // all records for all vessels added whilst offline
  selectedVesselRecordBookEntries: derived((state: TAppState) => {
    // if (!state.vessels.selectedVessel) return [];
    const records = getCurrentVesselRecords(state.vessels.selectedVessel!);
    const newVesselRecordBookEntries = state.recordBookEntries.filter(
      (entry) => entry.vesselId === state.vessels.selectedVesselId,
    );

    // need to remove any Pending record book entries that have been "synced" with the server.
    const sanitizedRecordBookEntries = newVesselRecordBookEntries.filter(
      (entry) =>
        !records.some(
          (rec) =>
            rec.managementActionId === entry.managementActionId &&
            rec.cleaningInspectionDate.toLocaleDateString() ===
              new Date(entry.cleaningInspectionDate).toLocaleDateString() &&
            rec.isAdHoc === entry.isAdHoc &&
            rec.synced === 'synced',
        ),
    );
    return records.concat(sanitizedRecordBookEntries);
  }),
  auth: {
    error: null,
    currentUser: null,
    isLoggedIn: derived((auth: TAuth) => auth.currentUser !== null),
    isLoggingIn: false,
  },
  // TODO(masm): mocking sync state, use real values
  app: {
    isSync: Math.random() >= 0.5,
    isOnline: window.navigator.onLine,
    networkStatus: derived((app: TApp) => (app?.isOnline ? 'ONLINE' : 'OFFLINE')),
  },
  iDb: null,
};
