import React from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselAfs_AFCoating as AfcCoating } from '../../overmind/effects/gql/graphql-types/fetchUserData'

const useStyles = makeStyles({
  root: {
    minWidth: 300,
    backgroundColor: '#fafafa',
    margin: '10px',
    marginRight: '20px',
  },
});

export type AfcCoatingInfoCardProps = {
  coating: AfcCoating | null;
};

const AfcCoatingInfoCard = ({ coating }: AfcCoatingInfoCardProps) => {
  const classes = useStyles();
  return (
      <Card className={classes.root} raised={false} >
        <CardContent>
        <Box display="flex" flexDirection="column"   borderRadius={4}>
          <Box display="flex" flexDirection="column" mb={1}>
            <Typography variant="caption" color="textSecondary">
              Name:
            </Typography>
            <Typography variant="body2">{coating?.ProductName}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" mb={1}>
            <Typography variant="caption" color="textSecondary">
              Coating:
            </Typography>
            <Typography variant="body2">{coating?.Type}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" mb={1}>
            <Typography variant="caption" color="textSecondary">
              Manufacturer:
            </Typography>
            <Typography variant="body2">{coating?.Manufacturer}</Typography>
          </Box>
        </Box>
        </CardContent>
        </Card>
      );
    };

export default AfcCoatingInfoCard
