import { gql, Query } from 'overmind-graphql';
import { fetchUserData as UserData, fetchUserDataVariables } from './graphql-types/fetchUserData';
import { fetchTxData, fetchTxDataVariables } from './graphql-types/fetchTxData';

export const fetchUserData: Query<UserData, fetchUserDataVariables> = gql`
  query fetchUserData($email: String, $today: timestamp) {
    User: biofouling_User(where: { Email: { _like: $email } }) {
      Id
      Name
      Email
      Password
      VesselParticularsUsers {
        VesselId
        VesselParticular {
          VesselName
          VesselImage
          IMONumber
          MMSINumber
          CallSign
          DWT
          Subscriptions(where: {_or: [{Status: {_eq: "Active"}}, {Status: {_eq: "Cancelled"}, EndsAt: {_gte: $today}}]}) {
            Name
            Status
            EndsAt
          }
          VesselBMPs {
            VesselBMPDocuments {
              DocumentId
              BMPDocumentType {
                TypeName
              }
              Document {
                Id
                Name
                Size
                User {
                  Id
                  Name
                }
              }
            }
          }
          VesselsBMRecords(where: { DeletedAt: { _is_null: true } }) {
            Id
            CleaningInspectionDate
            Location
            ManagementActions
            ResponsiblePerson
            ConflictId
            DeletedAt
            VesselAfsMaintenanceActionRecords {
              VesselAfsMaintenanceAction {
                Id
                IsAdHoc
                VesselsBMRecordType {
                  Id
                  TypeDescription
                }
                VesselNiche {
                  Id
                  NicheAreaDefinition {
                    Id
                    MainNicheArea
                    NicheArea
                  }
                }
              }
            }
            VesselBMRecordDocuments {
              Id
              Document {
                Id
                Name
                Size
                User {
                  Id
                  Name
                }
              }
            }
            UpdatedAt
            ModifiedBy
          }
          VesselActions: VesselAfsMaintenanceActions {
            Id
            IsAdHoc
            VesselsBMRecordType {
              Id
              TypeDescription
            }
            VesselNiche {
              Id
              NicheAreaDefinition {
                Id
                MainNicheArea
                NicheArea
              }
            }
          }
          VesselNiches {
            Id
            NicheAreaDefinition {
              Id
              MainNicheArea
              NicheArea
              BmRecordTypeNicheAreaDefinitions {
                VesselsBMRecordType {
                  Id
                  TypeDescription
                }
              }
            }
            VesselMaintenanceActions: VesselAfsMaintenanceActions {
              VesselsBMRecordType {
                Id
                TypeDescription
              }
              Id
              DetailedDescription
              ScheduleIntervalInMonths
              VesselAfsMaintenanceActionDocuments {
                Document {
                  FilePath
                }
              }
            }
          }
          VesselAfs {
            AfcDateApplied
            ExpectedLifeInYears
            AFCoating {
              ProductName
              Manufacturer
              Type
            }
            VesselAfsDocuments {
              Document {
                FilePath
              }
            }
            VesselAfsNiches {
              VesselNiche {
                NicheAreaDefinition {
                  MainNicheArea
                  NicheArea
                }
              }
            }
          }
        }
      }
      documentsByModifiedby {
        Id
        FilePath
      }
    }
    Port: biofouling_Port(order_by: { PortCode: asc }) {
      PortCode
      PortName
    }
    ActionsNiches: biofouling_BmRecordTypeNicheAreaDefinition {
      NicheAreaDefinition {
        Id
        MainNicheArea
        NicheArea
      }
      VesselsBMRecordType {
        Id
        TypeDescription
      }
    }
  }
`;

// fetch any offline syncTransaction entries for this user (userId) and have a status != synced
export const fetchOfflineRecordEntryData: Query<fetchTxData, fetchTxDataVariables> = gql`
  query fetchTxData($id: uuid) {
    offline_SyncTransaction(where: { _and: { UserId: { _eq: $id }, Status: { _neq: "synced" } } }) {
      Id
      Status
      StatusReason
      UserId
      NumTxRecords
      SyncDetails {
        Id
        TxId
        Status
        StatusReason
        CleaningInspectionDate
        Location
        IsAdHoc
        ManagementAction
        SyncNiches {
          VesselNicheId
        }
        ResponsiblePerson
      }
    }
  }
`;
