import React, { ReactNode, useCallback } from 'react';
import { css } from 'emotion';
import { useHistory } from 'react-router-dom';
import { useActions, useState } from '../../overmind/index';
import LoginForm from './LoginForm';
import background from './bg.jpg';

const LoginBackgroundOverlay = css`
  width: 100%;
  height: 100%;
  background: #1c2c43;
  opacity: 0.4;
`;

const LoginBackground = css`
  width: 100%;
  height: calc(100% - 56px);
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 56px;
`;

interface ILoginProps {
  onLoggedInChanged?: (loggedIn: boolean, user: object) => void;
  onLoginError?: (errors: string | Array<string>) => boolean;
  children?: any;
}

const Login = ({ children }: ILoginProps): JSX.Element => {
  const history = useHistory();
  const { isLoggedIn } = useState().auth;
  const {
    auth: { login },
  } = useActions();

  const callback = () => {
    history.push('/');
  };

  const handleSubmit = (credentials: { email: string; password: string }) => {
    login({ credentials, callback });
  };

  return !isLoggedIn ? (
    <div className={LoginBackground}>
      <div className={LoginBackgroundOverlay}></div>
      <LoginForm handleSubmit={handleSubmit} />
    </div>
  ) : (
    children
  );
};

export default Login;
