import React from 'react';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { grey } from '@material-ui/core/colors';

import { useState } from '../../overmind';

// const useRowStyles = makeStyles({
//   smallHeaderTablecell: {
//     backgroundColor: '#e2eff0',
//   },
//   attachmentsIcon: {
//     transform: 'rotate(90deg)',
//   },
//   attachmentsCell: {
//     width: '40px',
//     height: '40px',
//     fontSize: 12,
//     display: 'flex',
//     alignItems: 'center',
//     float: 'right',
//   },
// });

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props: { niche: any }) {
  const { niche } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {niche.NicheAreaDefinition.MainNicheArea}
        </TableCell>
        <TableCell>{niche.NicheAreaDefinition.NicheArea}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, background: grey[50] }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Interval</TableCell>
                    <TableCell>Description</TableCell>
                    {/* <TableCell align="right">Documentation</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {niche &&
                    niche.VesselMaintenanceActions?.map((action: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{action.VesselsBMRecordType.TypeDescription}</TableCell>
                        <TableCell>
                          {` ${action.ScheduleIntervalInMonths} ${
                            action.ScheduleIntervalInMonths > 1 ? ' months' : 'month'
                          }`}
                        </TableCell>
                        <TableCell>{action.DetailedDescription}</TableCell>
                        {/* <TableCell align="right">
                          {`(${action.VesselAfsMaintenanceActionDocuments.length})`}
                          <IconButton
                          //onClick={
                          //    openAttachments
                          // }
                          >
                            <AttachmentIcon />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ManagementPlanning = () => {
  const { selectedVessel } = useState().vessels;
  const vesselNiches = selectedVessel?.VesselParticular.VesselNiches;

  return (
    <Box px={4} mt={2} style={{ marginTop: '231px' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Main Area</TableCell>
              <TableCell>Sub Area</TableCell>
              {/* <TableCell align="center">Status</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {vesselNiches && vesselNiches.map((niche: any, index: number) => <Row key={index} niche={niche} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManagementPlanning;
