import { format } from 'date-fns';

export const MIKE_DATE_FORMAT = 'dd/MM/yyyy'; //ISO format
export const MIKE_DATETIME_FORMAT = 'dd MMM yyyy h:mm:ss a';
export const MIKE_TIME_FORMAT = 'h:mm:ss a';

export function formatDate(
  inputDate: string | Date | number | null | undefined,
  showTime?: boolean,
  showOnlyTime?: boolean,
): string {
  if (!inputDate) {
    return '';
  }
  try {
    const d = new Date(inputDate);
    const form = showOnlyTime ? MIKE_TIME_FORMAT : showTime ? MIKE_DATETIME_FORMAT : MIKE_DATE_FORMAT;
    const result = format(d, form);
    return result;
  } catch {
    return String(inputDate);
  }
}

export function getAvatarContentFromName(name: string) {
  const words = name.trim().split(' ');
  const initialsList = words.map((word: string) => {
    return word.substring(0, 1);
  });
  const t = initialsList.join('');
  return t;
}

export const Func = {
  map: <T, R>(f: (x: R) => R) => (g: (x: T) => R) => (x: T) => f(g(x)),
  contramap: <T, R>(g: (x: T) => R) => (f: (x: R) => R) => (x: T) => f(g(x)),
  // promap: <T, R>(f: (x: T) => R) => (g: (...args: any[]) => R) => (h: (x: T) => R) => Func.contramap(f)(Func.map(g)(h)),
  ap: <T, R>(f: (x: T) => (y: R) => R) => (g: (x: T) => R) => (x: T) => f(x)(g(x)),
  of: <T>(x: T) => () => x,
};
