import React from 'react';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselAfs_VesselAfsDocuments as VesselAfsDocuments } from '../../overmind/effects/gql/graphql-types/fetchUserData';
import { useBlobServices } from '../BlobUploadContext';
import { useState } from '../../overmind';

const primaryColor = '#3E5371';

const useStyles = makeStyles({
  attachmentsIcon: {
    transform: 'rotate(90deg)',
  },
});

export type AfcNichesListProps = {
  documents: VesselAfsDocuments[];
};

const AfcDocsList = ({ documents }: AfcNichesListProps) => {
  const { downloadService } = useBlobServices();
  const { currentUser } = useState().auth;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAttachment = (document: string | null) => {
    if (!document) return;
    downloadService.AccessToken = currentUser!.user.token;
    try {
      downloadService.downloadFile(document);
    } catch (error) {
      alert((error as Error).message);
    }
  };

  const docList =
    documents.length > 0
      ? documents.map((doc: VesselAfsDocuments) => {
          let filepath: string | null = null;
          let filename: string | null = null;
          if (doc.Document.FilePath) {
            const index = doc.Document.FilePath.lastIndexOf('/');
            filename = doc.Document.FilePath.substr(index + 1);
            filepath = doc.Document.FilePath;
          }
          return {
            filename,
            filepath,
          };
        })
      : [];

  return docList.length > 0 ? (
    <Box>
      <IconButton component="span" onClick={handleClick}>
        <AttachmentIcon className={classes.attachmentsIcon} />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {docList.map((doc) => (
          <MenuItem key={doc.filename} dense={true} onClick={() => handleDownloadAttachment(doc.filepath)}>
            <CloudDownloadIcon style={{ marginRight: '0.5rem', color: primaryColor }} fontSize="small" />
            <Typography variant="body2">{doc.filename}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  ) : (
    <Typography variant="body2">No attachements</Typography>
  );
};

export default AfcDocsList;
