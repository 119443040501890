import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { blueGrey, purple } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: '1rem',
    },
    tableRow: {
      borderBottom: '2px lightgrey solid',
    },
    actionHeader: {
      borderBottom: 'transparent',
    },
    responsibleColumn: {
      minWidth: '100px',
    },
    actionCell: {
      width: '40px',
      height: '40px',
      padding: '0px',
    },
    attachmentsCell: {
      width: '40px',
      height: '40px',
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
    },
    actionInfoCell: {
      height: '40px',
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
    },
    attachmentsIcon: {
      transform: 'rotate(90deg)',
    },
    syncCell: {
      width: '100px',
    },
    statusCell: {
      width: '75px',
    },
    addButton: {
      // label: {
      //   float: 'left',
      //   marginLeft: '10px',
      // },
    },
    dateColumn: {
      minWidth: '85px',
      maxWidth: '85px',
      // border: '1px solid red',
    },
    actionTypeColumn: {
      minWidth: '200px',
      maxWidth: '250px',
      // border: '1px solid red',
    },
    locationColumn: {
      minWidth: '50px',
      maxWidth: '50px',
      // border: '1px solid red',
    },
    descriptionColumn: {
      minWidth: '300px',
      // border: '1px solid red',
    },
    statusColumn: {
      minWidth: '50px',
      // border: '1px solid red',
    },
    addColumn: {
      width: '70px',
    },
    divider: {
      width: '2px',
      height: 'auto',
      display: 'inline-block',
    },
    descriptionInput: {
      flexGrow: 1,
      resize: 'both',
      fontSize: '0.875rem',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
    chip: {
      boxSizing: 'unset',
      border: '0px',
    },
    chipIcon: {
      paddingBottom: '5px',
    },
    chipLabel: {
      lineHeight: '0',
      fontSize: '0.7rem',
      paddingTop: '4px',
    },
    chipSublabel: {
      lineHeight: '1',
      fontSize: '0.6rem',
    },
    iconContingecy: {
      transform: 'scale(0.6)',
      color: '#FFF',
      // color: theme.palette.getContrastText(blueGrey[700]),
      backgroundColor: purple[700],
    },
    iconPlanned: {
      transform: 'scale(0.6)',
      color: '#FFF',
      // color: theme.palette.getContrastText(blueGrey[700]),
      backgroundColor: blueGrey[700],
    },
    readOnly: {
      backgroundColor: '#fafafa',
      marginLeft: '-20px',
      marginRight: '-20px',
      paddingRight: '40px',
      paddingLeft: '20px',
      // backgroundColor: '#455a6412',
    },
    conflict: {
      // backgroundColor: '#f18977',
    },
    expander: {
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
    },
    expanderOpen: {
      transform: 'rotate(-180deg)',
    },
    expanderClosed: {
      transform: 'rotate(0)',
    },
    panelRecords: {
      flexDirection: 'column',
      height: 'calc(100vh - 400px)',
      overflow: 'auto',
    },
    paperPlain: {
      backgroundColor: '#fafafa',
    },

    paper: {
      'position': 'relative',
      'width': '95%',
      'maxWidth': '100%',
      'minWidth': '400px',
      'height': 'calc(100vh - 275px)',
      'margin': '0 auto',
      'background': '#fafafa',
      'borderRadius': '10px',
      'boxShadow': '0 2px 8px rgba(0,0,0,.3)',
      'overflow': 'hidden',
      '& before': {
        content: '',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: '60px',
        background: 'radial-gradient(#575450 6px, transparent 7px) repeat-y',
        backgroundSize: '30px 30px',
        borderRight: '3px solid #D44147',
        boxSizing: 'border-box',
      },
    },

    paperContent: {
      position: 'absolute',
      top: '30px',
      right: '10px',
      bottom: '30px',
      left: '20px',
      background: 'linear-gradient(transparent, transparent 29px, #91D1D31A 29px)',
      backgroundSize: '30px 30px',
    },

    actionLabel: {
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      lineHeight: '1.2em',
    },
  }),
);

export { useStyles };
