import React, { useContext } from 'react';
import { BlobDownloadService } from '../overmind/effects/storage/Azure/BlobDownloadService';
import { BlobStorageService } from '../overmind/effects/storage/Azure/BlobStorage';
import { BlobUploadService } from '../overmind/effects/storage/Azure/BlobUploadService';

const blobStorageService = new BlobStorageService();
const blobUploadService = new BlobUploadService(blobStorageService);
const blobDownloadService = new BlobDownloadService(blobStorageService);

const blobServices = {
  uploadService: blobUploadService,
  downloadService: blobDownloadService,
};

const AzureBlobContext = React.createContext(blobServices);

const useBlobServices = () => useContext(AzureBlobContext);

export { blobServices, AzureBlobContext, useBlobServices };
