import { VesselNich } from './../../vcTypes';
import { AsyncAction, Action, mutate, pipe } from 'overmind';
import { v4 as uuidv4 } from 'uuid';
import { AddRecordBookEntry, AddRecordBookEntryVariables } from '../effects/gql/graphql-types/AddRecordBookEntry';
import { FilePondFile } from 'filepond';

import {
  ProcessRecordBookEntry,
  ProcessRecordBookEntryVariables,
} from '../effects/gql/graphql-types/ProcessRecordBookEntry';
import {
  fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselNiches as VesselNiches,
  fetchUserData_ActionsNiches_NicheAreaDefinition as NicheAreaDefinition,
  fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselsBMRecords_VesselBMRecordDocuments as VesselBMRecordDocuments,
} from '../effects/gql/graphql-types/fetchUserData';
import { TAppState, TFileAttachment, TRecordBookEntry } from '../state';
import {
  offline_SyncDetail_insert_input,
  offline_SyncDocuments_insert_input,
  offline_SyncNiche_insert_input,
} from '../graphql-global-types';

import { BlobFileUpload } from '../effects/storage/Azure/blob-storage';
import { map, scan, take } from 'rxjs/operators';
import { interval, Observable } from 'rxjs';
import { strictEqual } from 'assert';

const mergeRecordBookEntries = (existingEntry: TRecordBookEntry, newEntry: TRecordBookEntry): TRecordBookEntry => {
  existingEntry.description = `${existingEntry.description}  ${newEntry.description}`;
  existingEntry.vesselNiches = [...existingEntry.vesselNiches, ...newEntry.vesselNiches];
  return existingEntry;
};

const saveRecordBookEntries: AsyncAction = async ({ state, effects }: { state: TAppState; effects: any }) => {
  await effects.storage.saveRecords(state, state.recordBookEntries);
};

const addRecordBookEntry: AsyncAction<TRecordBookEntry, any> = async (
  { state, effects }: { state: TAppState; effects: any },
  recordBookEntry,
) => {
  // some invariant check.
  // If record date entry already exists, merge entries.

  let newEntry = recordBookEntry;
  // const found = state.recordBookEntries.find(
  //   (entry) =>
  //     new Date(entry.cleaningInspectionDate).toDateString() ===
  //     new Date(newEntry.cleaningInspectionDate).toDateString(),
  // );
  // if (found) {
  //   newEntry = mergeRecordBookEntries(found, recordBookEntry);
  //   state.recordBookEntries = [...state.recordBookEntries];
  // } else {
  //   state.recordBookEntries = [...state.recordBookEntries, newEntry];
  // }
  state.recordBookEntries = [...state.recordBookEntries, newEntry];
  // state.recordBookEntries.push(newEntry);
  await effects.storage.saveRecords(state, state.recordBookEntries);
};

// const deleteRecordBookEntry: AsyncAction<TRecordBookEntry | number, any> = async (
//   {
//     state,
//     effects,
//   }: {
//     state: TAppState;
//     effects: any;
//   },
//   recordBookEntry,
// ) => {
const deleteRBE = async (
  recordBookEntry: TRecordBookEntry | number,
  deleteAttachements: boolean,
  state: TAppState,
  effects: any,
) => {
  const getIndex = (record: TRecordBookEntry) =>
    state.recordBookEntries.findIndex(
      (value) =>
        value.cleaningInspectionDate === record.cleaningInspectionDate &&
        value.managementAction === record.managementAction,
    );

  const index = typeof recordBookEntry === 'number' ? recordBookEntry : getIndex(recordBookEntry);
  if (index >= 0) {
    // delete state.recordBookEntries[index];
    deleteAttachements &&
      state.recordBookEntries[index].documents.map(
        async (doc) => await effects.storage.deleteFileById(doc.Document.Id),
      );
    state.recordBookEntries.splice(index, 1);
    state.recordBookEntries = [...state.recordBookEntries];
  }
  await effects.storage.saveRecords(state, state.recordBookEntries);
};

const deleteRecordBookEntry: AsyncAction<TRecordBookEntry | number, any> = async (
  {
    state,
    effects,
  }: {
    state: TAppState;
    effects: any;
  },
  recordBookEntry,
) => {
  await deleteRBE(recordBookEntry, false, state, effects);
};

const deleteRecordBookEntryAndAttachements: AsyncAction<TRecordBookEntry | number, any> = async (
  {
    state,
    effects,
  }: {
    state: TAppState;
    effects: any;
  },
  recordBookEntry,
) => {
  await deleteRBE(recordBookEntry, true, state, effects);
};

const sortRecordBookEntries: Action<(a: any, b: any) => number> = ({ state }: { state: TAppState }, comparator) => {
  state.selectedVesselRecordBookEntries = state.selectedVesselRecordBookEntries.slice().sort(comparator);
};

const deleteDocument: AsyncAction<{ record: TRecordBookEntry; id: string }, any> = async (
  {
    state,
    effects,
  }: {
    state: TAppState;
    effects: any;
  },
  { record, id }: { record: TRecordBookEntry; id: string },
) => {
  const getIndex = (record: TRecordBookEntry) => {
    console.log('get index in deleteDocument', { record, id });
    return state.recordBookEntries.findIndex(
      (value) =>
        value.cleaningInspectionDate === record.cleaningInspectionDate &&
        value.managementAction === record.managementAction,
    );
  };
  const index = getIndex(record);

  const documents = record.documents.filter((doc) => doc.Document.Id !== id);
  // record.documents = documents;

  state.recordBookEntries[index].documents = documents;
  await effects.storage.deleteFileById(id);
  await effects.storage.saveRecords(state, state.recordBookEntries);
};

const saveDocuments: AsyncAction<{ record: TRecordBookEntry | null; files: FilePondFile[] }, any> = async (
  {
    state,
    effects,
  }: {
    state: TAppState;
    effects: any;
  },
  { record, files }: { record: TRecordBookEntry | null; files: FilePondFile[] },
) => {
  if (!record) return;

  // Add each attached file to the records doc array and save the file/blob to indexedDb
  const documents: VesselBMRecordDocuments[] = files.map((file: FilePondFile) => {
    const fileId = uuidv4();
    const doc = {
      Id: fileId,
      Name: file.filename,
      Size: file.fileSize,
      User: {
        Id: state.auth.currentUser!.userId,
        Name: state.auth.currentUser!.user.user,
      },
    };

    // const reader = new FileReader();
    // reader.readAsArrayBuffer(file.file);
    // reader.onload = (e) => {
    //   console.log(`Saving ${file.filename}, ${(reader.result as ArrayBuffer).byteLength} bytes`);
    // };

    file.file.arrayBuffer().then((blob) => {
      console.log(`Saving ${file.filename}, ${blob.byteLength} bytes`);
      effects.storage.saveFile({
        id: fileId,
        vesselid: record.vesselId,
        filename: file.filename,
        filetype: file.fileType,
        filesize: file.fileSize,
        file: blob,
      });
    });
    // effects.storage.saveFile({
    //   filename: file.filename,
    //   filetype: file.fileType,
    //   filesize: file.fileSize,
    //   file: file.file,
    // });

    return {
      Id: null,
      Document: doc,
    };
  });

  const index = state.recordBookEntries.findIndex(
    (value) =>
      value.cleaningInspectionDate === record.cleaningInspectionDate &&
      value.managementAction === record.managementAction,
  );

  state.recordBookEntries[index].documents = [...state.recordBookEntries[index].documents, ...documents];
  // save updated records to indexedDB
  await effects.storage.saveRecords(state, state.recordBookEntries);
  console.log(`Doc list for record`);
};

// const uploadRecordAttachments = async (rbes: TRecordBookEntry[], state: TAppState, effects: any) => {
//   for (let rbe of rbes) {
//     const files = [];
//     const indexDbFiles = rbe.documents.map(async (doc) => await effects.storage.getFileById(doc.Document.Id));
//     for await (let file of indexDbFiles) {
//       files.push(file);
//     }
//     console.dir(files);
//     state.blobUploadService!.uploadFiles(files);
//   }
// };

/**
 * Responsible for uploading all non-synced records in the recordBookEntries array, to
 * the offline schema in Hasura.
 *
 * @param {{
 *     state: TAppState;
 *     effects: any;
 *   }} {
 *     state,
 *     effects,
 *   }
 * @param {*} recordBookEntries
 * @returns
 */
const transferRecordBookEntries: AsyncAction<TRecordBookEntry[], AddRecordBookEntry> = async (
  {
    state,
    effects,
  }: {
    state: TAppState;
    effects: any;
  },
  recordBookEntries,
) => {
  if (!state.app.isOnline) return null;

  // reshape into GQL
  const assignNiches = (niches: Array<NicheAreaDefinition>, actionId: number): offline_SyncNiche_insert_input[] => {
    let syncNiches: offline_SyncNiche_insert_input[];
    const vessel = state.vessels.selectedVessel;
    syncNiches = niches.map((niche) => {
      // Note: we need to send the vessel's niche area ID and not the niche area ID,
      // which is what stored here in niche.Id .
      const vesselNiche: VesselNiches | undefined = vessel?.VesselParticular.VesselNiches.find(
        (vniche) => vniche.NicheAreaDefinition?.Id === niche.Id,
      );
      if (!vesselNiche) return {};

      const vesselAfsMaintenanceAction = vesselNiche.VesselMaintenanceActions.find(
        (action) => action.VesselsBMRecordType!.Id === actionId,
      );

      return {
        VesselNicheId: vesselNiche.Id,
        VesselAFSMaintenanceId: vesselAfsMaintenanceAction?.Id,
      };
    });

    return syncNiches;
  };

  const assignDocuments = (documents: VesselBMRecordDocuments[]) => {
    let syncDocuments: offline_SyncDocuments_insert_input[];
    let fileAttachements: TFileAttachment[] = [];
    syncDocuments = documents.map((document) => {
      const syncDocument = {
        FileId: document.Document.Id,
        FileName: document.Document.Name,
        FileUrl: `${document.Document.Id}/${document.Document.Name}`,
        SizeBytes: document.Document.Size,
        UploadedBy: document.Document.User!.Id,
      };
      console.dir(syncDocument);
      return syncDocument;
    });

    return syncDocuments;
  };

  const assignDetails = (rbes: TRecordBookEntry[]): offline_SyncDetail_insert_input[] => {
    let syncDetails: offline_SyncDetail_insert_input[];
    syncDetails = rbes.map((rbe) => ({
      CleaningInspectionDate: new Date(rbe.cleaningInspectionDate).toISOString(),
      IsAdHoc: rbe.isAdHoc,
      Location: rbe.location,
      ActivityTypeId: rbe.managementActionId,
      ManagementAction: rbe.description,
      ResponsiblePerson: rbe.responsiblePerson,
      SyncNiches: { data: assignNiches(rbe.vesselNiches, rbe.managementActionId) },
      SyncDocuments: { data: assignDocuments(rbe.documents) },
    }));

    return syncDetails;
  };

  let syncResult: any = null;

  const recordsToSync = recordBookEntries.filter((rbe) => rbe.synced !== 'synced');

  if (recordsToSync.length > 0) {
    const variables: AddRecordBookEntryVariables = {
      rbe: {
        NumTxRecords: recordsToSync.length,
        VesselId: state.vessels.selectedVesselId,
        UserId: state.auth.currentUser?.userId,
        TxDateTime: new Date().toISOString(),
        SyncDetails: { data: assignDetails(recordsToSync) },
      },
    };

    // Insert records into Offline schema
    // syncResult = uuidv4();
    syncResult = await effects.gql.mutations.addRecordBookEntry(variables);
    console.log(`Transferred record book entries: TxId = ${JSON.stringify(syncResult, null, 2)}`);

    //
    // The following causes Overmindjs to throw an exception due to the mutation of the state property "uploadedFiles"
    // which overmind believes is happening outside of "action". OvermindJS mutation tracking doesnt play nice with
    // rxjs Observables and subscriptions. The Asynchronous nature of rxjs calls cuases issues (have tried various
    // workarounds with no success).
    // OvermindJS Exception:
    //
    // Error: proxy-state-tree - You are mutating the path "uploadedFiles", but it is not allowed. The following could have happened:
    //  - The mutation is explicitly being blocket
    //  - You are passing state to a 3rd party tool trying to manipulate the state
    //  - You are running asynchronous code and forgot to "await" its execution
    //
    // state
    //   .blobUploadService!.uploadedFiles.pipe(map((files: BlobFileUpload[]) => (state.uploadedFiles = files)))
    //   .subscribe();

    // Upload all documents attached to records.
    // if (state.blobUploadService) {
    //   state.blobUploadService.accessToken = state.auth.currentUser!.user.token;
    //   uploadRecordAttachments(recordsToSync, state, effects);
    // }

    // const recordsSynced = recordsToSync.map((rbe) => ({
    //   ...rbe,
    //   synced: true,
    // }));

    const getIndex = (record: TRecordBookEntry) =>
      state.recordBookEntries.findIndex(
        (value) =>
          value.cleaningInspectionDate === record.cleaningInspectionDate &&
          value.managementAction === record.managementAction,
      );

    const recordsSynced = recordsToSync.map((rbe) => {
      const index = getIndex(rbe);
      state.recordBookEntries[index].synced = 'pending';
    });

    state.recordBookEntries = [...state.recordBookEntries];

    // state.recordBookEntries = [...recordBookEntries.filter((rbe) => rbe.synced === true), ...recordsSynced];
    await effects.storage.saveRecords(state, state.recordBookEntries);
  }

  return syncResult;
};

const syncRecordBookEntries: AsyncAction<string, ProcessRecordBookEntry> = async (
  { state, effects }: { state: TAppState; effects: any },
  txId,
) => {
  const variables: ProcessRecordBookEntryVariables = { id: txId };
  const syncResult = await effects.gql.mutations.processRecordBookEntry(variables);
  console.log(`Synchronization for transaction ID: ${txId} initiated.`);
  return syncResult;
};

const uploadRbeAssets: AsyncAction<TRecordBookEntry[], any> = async () => {};

export {
  saveRecordBookEntries,
  addRecordBookEntry,
  deleteRecordBookEntry,
  deleteRecordBookEntryAndAttachements,
  sortRecordBookEntries,
  transferRecordBookEntries,
  syncRecordBookEntries,
  saveDocuments,
  deleteDocument,
  uploadRbeAssets,
};
