import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import { ThemeProvider } from '@material-ui/core/styles';

import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import Homepage from './Homepage';
import Login from './Login/Login';
import TopBar from './Topbar/index';

import { theme } from '../theme';
import { config } from '../overmind/index';
import { blobServices, AzureBlobContext } from './BlobUploadContext';

const overmind = createOvermind(config, {
  devtools: 'localhost:3031',
});

const App: React.FC = () => {
  useEffect(() => {
    async function workaroundSafariBug() {
      // const isSafari = /Safari\//.test(navigator.userAgent) && !/Chrom(e|ium)\//.test(navigator.userAgent);

      // // No point putting other browsers through this mess.
      // if (!isSafari || !indexedDB.databases) return Promise.resolve();

      let intervalId: NodeJS.Timeout;

      return new Promise<void>((resolve) => {
        const tryIdb = () => indexedDB.databases().finally(resolve);
        intervalId = setInterval(tryIdb, 100);
        tryIdb();
      }).finally(() => clearInterval(intervalId));
    }
    workaroundSafariBug()
      .then(() => console.log(`IndexDb resolved`))
      .catch(() => console.log(`IndexedDb rejected`));
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Provider value={overmind}>
        <AzureBlobContext.Provider value={blobServices}>
          <BrowserRouter>
            <TopBar />
            <Login>
              <Homepage />
            </Login>
          </BrowserRouter>
        </AzureBlobContext.Provider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
