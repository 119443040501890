import { TransferProgressEvent } from '@azure/core-http';
import { AnonymousCredential, BlobServiceClient, BlockBlobClient, newPipeline } from '@azure/storage-blob';
import { from, Observable, OperatorFunction, Subscriber } from 'rxjs';
import { distinctUntilChanged, map, scan } from 'rxjs/operators';
import { TFileAttachment } from '../../../state';
import { BlobContainerRequest, BlobFile, BlobFileRequest, BlobStorageRequest } from './blob-storage';
import { SasService } from './SasService';

class BlobStorageService {
  getStorageAccessWithContainer(accessToken: string): Observable<BlobContainerRequest> {
    return SasService.getSasToken(accessToken).pipe(
      map((sas: BlobStorageRequest) => ({
        ...sas,
        containerName: SasService.BLOB_CONTAINER!,
      })),
    );
  }

  downloadBlobFile(request: BlobFileRequest) {
    const blockBlobClient = this.getBlockBlobClient(request);
    return from(blockBlobClient.download());
  }

  uploadToBlobStorage(file: TFileAttachment, request: BlobFileRequest) {
    const blockBlobClient = this.getBlockBlobClient(request);
    return this.uploadFile(blockBlobClient, file);
  }

  scanFiles = <T extends BlobFile>(): OperatorFunction<T, T[]> => (source) =>
    source.pipe(
      map((file) => ({
        [`${file.containerName}/${file.filename}`]: file,
      })),
      scan<Record<string, T>, Record<string, T>>((files, file) => ({ ...files, ...file }), {}),
      map((files) => Object.values(files)),
    );

  private getBlockBlobClient(request: BlobFileRequest) {
    const containerClient = this.getContainerClient(request);
    return containerClient.getBlockBlobClient(request.filename);
  }

  private getContainerClient(request: BlobContainerRequest) {
    const blobServiceClient = this.getBlobServiceClient(request);
    return blobServiceClient.getContainerClient(request.containerName);
  }

  private getBlobServiceClient(request: BlobStorageRequest) {
    const pipeline = newPipeline(new AnonymousCredential());
    return new BlobServiceClient(request.storageUri, pipeline);
  }

  private uploadFile(blockBlobClient: BlockBlobClient, file: TFileAttachment) {
    return new Observable<number>((observer) => {
      blockBlobClient
        .uploadData(file.file, {
          onProgress: this.onProgress(observer),
          blobHTTPHeaders: {
            blobContentType: file.filetype,
          },
        })
        .then(this.onUploadComplete(observer, file), this.onUploadError(observer));
    }).pipe(distinctUntilChanged());
  }

  private onUploadComplete(observer: Subscriber<number>, file: TFileAttachment) {
    return () => {
      console.log('onProgress complete');
      observer.next(file.filesize);
      observer.complete();
    };
  }

  private onUploadError(observer: Subscriber<number>) {
    return (error: any) => observer.error(error);
  }

  private onProgress(observer: Subscriber<number>) {
    return (progress: TransferProgressEvent) => observer.next(progress.loadedBytes);
  }
}

export { BlobStorageService };
