import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Controller } from 'react-hook-form';
type OptionValue = string | number;

// type Option<T extends OptionValue> = {
//   value: T;
//   label: string;
// };

// type Props<T extends OptionValue> = {
//   name: string;
//   label: string;
//   control:
//   value?: T;
//   options: Option<T>[] | undefined;
//   onSelect?: (value: T) => void;
//   ref: any;
// };

type TProps<T extends OptionValue> = {
  name: string;
  label: string;
  control: any;
  rules?: Object;
  handleChange?: (value: T) => void;
  defaultValue: T;
  children: React.ReactNode;
};

const Selector = <T extends OptionValue>({
  name,
  label,
  control,
  defaultValue,
  children,
  ...rest
}: TProps<T> & FormControlProps) => {
  // const { name, label, control, defaultValue, children, } = props;

  const labelId = `${name}-select-label`;

  return (
    <FormControl {...rest}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: {onChange, value}}) => (
          <Select labelId={labelId} label={label}>
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
};

const Selector2 = <T extends OptionValue>({
  name,
  label,
  control,
  defaultValue,
  rules,
  handleChange,
  children,
  ...rest
}: TProps<T> & FormControlProps) => {
  // const { name, label, control, defaultValue, children, } = props;

  const labelId = `${name}-select-label`;

  return (
    <FormControl {...rest}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: {onChange, value}}) => (
          <Select
            labelId={labelId}
            label={label}
            value={value}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              handleChange && handleChange(e.target.value as T);
              onChange(e.target.value as T);
            }}
          >
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
};

export { Selector2 };
export default Selector;
