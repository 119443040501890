import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontSize: '16px',
        lineHeight: '22px',
      },
      input: {
        color: '#3E5371',
      },
    },
  },
  palette: {
    primary: {
      main: '#3E5371',
    },
    // text: {
    //   primary: '#3E5371',
    //   secondary: 'red',
    // },
  },
  typography: {
    fontFamily: 'Roboto',
    // allVariants: {
    //   color: '#3E5371',
    // },
  },
});
