import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useActions } from '../../overmind/index';
import { css } from 'emotion';

import { useState } from '../../overmind';

const LoginFormStyle = css`
  position: absolute;
  top: calc(50% - 168px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  width: 462px;
  height: 386px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;
const LoginButtonStyle = css`
  margin: 16px auto 0 auto !important;
  width: 328px;
  height: 48px !important;
  position: relative !important;
  display: block !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: 0.0125em !important;
`;
const logo_message = css`
  margin: 16px 0 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #3e5371;
`;
const textFieldStyle = css`
  border: 1px solid red;
`;
const textFieldContainer = css`
  margin: 16px auto 0 auto;
  padding: 15px 16px 12px 16px;
  width: 328px;
  border: 1px solid #3e5371;
  box-sizing: border-box;
  border-radius: 4px;
`;

interface ILoginFormProps {
  pristine?: boolean;
  submitting?: boolean;
  handleSubmit: (values: any) => void;
}

const LoginForm: React.FC<ILoginFormProps> = ({ pristine, submitting, handleSubmit }) => {
  const { isLoggingIn, error } = useState().auth;
  const {
    auth: { clearError },
  } = useActions();
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');

  return (
    <form className={LoginFormStyle}>
      <div className={logo_message}>
        <img src="/vesselcheck-checkmark-logo.png" alt="Vesselcheck Logo" />
        <div>Welcome back</div>
      </div>
      {error ? (
        <div>
          <Typography variant="h6" color="error" align="center">
            {error.title}
          </Typography>
          <Typography variant="body2" color="error" align="center">
            {error.detail}
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h6" style={{ opacity: 0 }} align="center">
            _
          </Typography>
        </div>
      )}

      <div>
        <div className={textFieldContainer}>
          <TextField
            type="text"
            className={textFieldStyle}
            value={name}
            placeholder="Username"
            InputProps={{ disableUnderline: true }}
            fullWidth={true}
            onChange={(e) => {
              if (error) clearError();

              setName(e.target.value);
            }}
          />
        </div>
        <div className={textFieldContainer}>
          <TextField
            type="password"
            className={textFieldStyle}
            value={password}
            placeholder="Password"
            InputProps={{ disableUnderline: true }}
            fullWidth={true}
            onChange={(e) => {
              if (error) clearError();

              setPassword(e.target.value);
            }}
          />
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            handleSubmit({ email: name, password });
          }}
          className={LoginButtonStyle}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoggingIn}
        >
          {!isLoggingIn ? 'SIGN IN' : <CircularProgress size={24} />}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
