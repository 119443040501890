import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import { useState } from '../../overmind';
import { fetchUserData_User_VesselParticularsUsers as VesselDetails } from '../../overmind/effects/gql/graphql-types/fetchUserData';

import VesselCard from '../Vessel/VesselCard/index';

const VesselCardSkeleton = () => {
  return (
    <Paper>
      <Box p={2}>
        <Skeleton variant="rect" height={100} />
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" height={50} />
      </Box>
    </Paper>
  );
};

const VesselList = () => {
  const vessels = useState().vessels.userVesselData?.VesselParticularsUsers;

  return (
    <Box px={4} mt={2} style={{marginTop: '70px'}}>
      <Grid container spacing={3}>
        {vessels && vessels.length > 0
          ? vessels.map((vessel: VesselDetails, index: number) => {
              return (
                <Grid item key={vessel.VesselId} xs={12} sm={6} md={3}>
                  <VesselCard vessel={vessel} key={index} />
                </Grid>
              );
            })
          : [1, 2, 3, 4].map((value) => (
              <Grid item key={value} xs={12} sm={6} md={3}>
                <VesselCardSkeleton />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
};

export default VesselList;
