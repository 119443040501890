import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

import { fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselAfs_VesselAfsNiches as VesselNiches } from '../../overmind/effects/gql/graphql-types/fetchUserData'

export type AfcNichesListProps = {
  niches: VesselNiches[]
};

const AfcNichesList = ({ niches }: AfcNichesListProps ) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const nicheList =
    niches.length > 0
      ? niches.map((niche: VesselNiches) => {
          return {
            mainArea: niche.VesselNiche.NicheAreaDefinition?.MainNicheArea,
            nicheArea: niche.VesselNiche.NicheAreaDefinition?.NicheArea,
          };
        })
      : [];

  return niches.length > 0 ? (
    <Box>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        endIcon={<ExpandMore fontSize="small" />}
        onClick={handleClick}
      >
        {`${niches.length} ${niches.length === 1  ? 'Niche' : 'Niches'}`}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {nicheList.map((evidence: any) => (
          <MenuItem dense={true}>
            <Box>
              <Typography variant="body2">{evidence.mainArea}</Typography>
              <Typography variant="body2" color="textSecondary">
                {evidence.nicheArea}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  ) : (
    <Typography variant="body2">No niches</Typography>
  );
}

export default AfcNichesList;
