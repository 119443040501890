import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { green, red, orange } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { TRecordSync } from '../overmind/state';

const SyncIcon = ({ isSync, disabled, classes }: { isSync: TRecordSync; disabled: boolean; classes?: any }) => {
  switch (isSync) {
    case 'synced':
      return (
        <Box display="flex" alignItems="center" minWidth="75px">
          <Typography variant="subtitle2">Synced</Typography>
          <CheckCircleIcon style={{ color: green[500], marginLeft: '0.25rem' }} />
        </Box>
      );
    case 'notsynced':
      return (
        <Box display="flex" alignItems="center" minWidth="75px">
          <Typography variant="subtitle2">Not Synced</Typography>
          <ErrorIcon style={{ color: red[500], marginLeft: '0.25rem' }} />
        </Box>
      );
    case 'pending':
      return (
        <Box display="flex" alignItems="center" minWidth="75px">
          <Typography variant="subtitle2">Pending</Typography>
          <ErrorIcon style={{ color: orange[500], marginLeft: '0.25rem' }} />
        </Box>
      );
    default:
      return null;
  }
};

export default SyncIcon;
