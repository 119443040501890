import React, { Children, createContext, forwardRef, useContext, cloneElement } from 'react';
import { TextField, CircularProgress, Chip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions, AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Controller, UseControllerProps, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { PortDetail } from '../overmind/state';
import { setTimeout } from 'timers';

const useStyles = makeStyles({
  option: {
    'fontSize': 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  chip: {
    marginRight: 10,
  },
});

const portFilter = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: PortDetail) => option.PortName || '',
});

const LISTBOX_PADDING = 8;

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps}></div>;
});

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = forwardRef<HTMLDivElement>((props, ref) => {
  const { children, ...other } = props;
  const itemData = Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

type TProps<T> = {
  label: string;
  options: PortDetail[];
  handleChange?: (value: T[]) => void;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
};

// const MultiSelector = () => {
//   return <div>MultiSelector</div>;
// };
type PortSelectorProps<T> = TProps<T> & UseControllerProps;

const PortSelector = <T,>({
  name,
  label,
  control,
  options,
  defaultValue,
  setValue,
  getValues,
  rules,
  handleChange,
  ...rest
}: PortSelectorProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  // const loading = open && options.length === 0;
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={options}
          value={value}
          onChange={(e: React.ChangeEvent<Record<string, unknown>>, value: unknown) => {
            onChange(value);
            setValue(name, value);
          }}
          classes={{
            option: classes.option,
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          autoHighlight
          loading={loading}
          disableListWrap
          ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
          renderGroup={renderGroup}
          filterOptions={portFilter}
          getOptionSelected={(option, value) => option.PortCode === value.PortCode}
          getOptionLabel={(option) => option.PortName || ''}
          renderOption={(option) => (
            <>
              <span>{option.CountryCode}</span>
              <Chip className={classes.chip} label={option.PortCode} size="small" color="primary" variant="outlined" />
              {option.PortName}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Choose location from ${options.length} ports`}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default PortSelector;
