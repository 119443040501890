import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, map } from 'rxjs/operators';
import { BlobStorageRequest } from './blob-storage';

class SasService {
  static readonly SAS_API_URL = `${process.env.REACT_APP_WEBAPI_URI}/getSAS`;
  static readonly BLOB_PERMISSIONS = 'raw';
  static readonly BLOB_CONTAINER = process.env.REACT_APP_DOCUMENTS_CONTAINER;

  static getSasToken(accessToken: string): Observable<BlobStorageRequest> {
    return fromFetch(SasService.SAS_API_URL, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        container: 'vesselcheckdocuments',
        permissions: 'raw',
      }),
      selector: (response: any) => response.json() as Observable<BlobStorageRequest>,
    }).pipe(
      map((json: any) => ({
        storageUri: json.url,
        storageAccessToken: json.token,
      })),
    );
  }
}

export { SasService };
