import { BlobDownloadResponseParsed } from '@azure/storage-blob';
import { from, OperatorFunction, Subject, of, throwError} from 'rxjs';
import { catchError, tap, map, mergeMap, switchMap } from 'rxjs/operators';
import { BlobContainerRequest, BlobFileDownload, BlobFileRequest } from './blob-storage';
import { BlobStorageService } from './BlobStorage';

class BlobDownloadService {
  accessToken: string | undefined;

  private downloadQ = new Subject<string>();

  constructor(private blobStorage: BlobStorageService) {
    this.accessToken = undefined;
  }

  get downloadQueue() {
    return this.downloadQ.asObservable();
  }

  set AccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  downloadedFiles = this.downloadQueue.pipe(
    mergeMap((filename) => this.downloadAzureFile(filename).pipe(catchError((err) => of({filename: 'Error', containerName: err.message, url:''})))),
    this.blobStorage.scanFiles(),
  );

  downloadFile(filename: string): void {
    // console.log(`Queuing: ${filename}`);
    this.downloadQ.next(filename);
  }

  private downloadAzureFile = (filename: string) => {
    // console.log(`downloadAzureFile: ${filename}`);
    return this.blobStorage.getStorageAccessWithContainer(this.accessToken!).pipe(
      switchMap((result) =>
        this.blobStorage
          .downloadBlobFile({
            ...result,
            filename,
          })
          .pipe(
            this.getBlobUrlfromResponse(),
            this.mapDownloadResponse({ ...result, filename })
          ),
      ),

    );
  };

  private getBlobUrlfromResponse = (): OperatorFunction<BlobDownloadResponseParsed, string> => (source) =>
    source.pipe(
      // tap((response) => console.log(`getBlobUrlfromResponse: ${response.contentLength}`)),
      switchMap((response) =>
        from(response.blobBody ? response.blobBody : Promise.resolve({})).pipe(
          map((body) => URL.createObjectURL(body)),
        ),
      ),
    );

  private mapDownloadResponse = (options: BlobFileRequest): OperatorFunction<string, BlobFileDownload> => (source) =>
    source.pipe(
      // tap((url) => console.log(`mapDownloadResponse: ${url}`)),
      tap((url) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = options.filename;
        anchor.click();
        URL.revokeObjectURL(url);
      }),
      map((url) => ({
        filename: options.filename,
        containerName: options.containerName,
        url,
      })),
    );
}
export { BlobDownloadService };
