import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { fetchUserData_ActionsNiches_NicheAreaDefinition as NicheAreaDefinition } from '../overmind/effects/gql/graphql-types/fetchUserData';

interface NicheChipProps {
  niche: NicheAreaDefinition;
  classes: any;
}

const NicheChip = (props: NicheChipProps & ChipProps) => {
  const { niche, classes, ...rest } = props;
  return (
    <Box p={0.25}>
      <Chip
        {...rest}
        className={classes.chip}
        label={
          <Box p={0.25} display="block">
            <Typography className={classes.chipLabel} variant="body2" color="textPrimary">
              {niche.MainNicheArea}
            </Typography>

            <Typography className={classes.chipSublabel} variant="caption" color="secondary">
              {niche.NicheArea}
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default NicheChip;
