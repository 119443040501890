import { AsyncAction } from 'overmind';
import { TAppState } from '../state';

const clearError = ({ state }: { state: TAppState }) => {
  state.auth.error = null;
};

const login = async (
  { state, effects }: { state: TAppState; effects: any },
  { credentials, callback }: { credentials: { email: string; password: string }; callback: () => void },
) => {
  state.auth.isLoggingIn = true;
  let user = null;
  try {
    user = await effects.auth.doLogin(credentials.email, credentials.password, state.app.isOnline);
  } catch (error) {
    state.auth.error = error;
  }
  state.auth.isLoggingIn = false;
  console.log('login user: ', user);
  if (!user) return;

  state.auth.currentUser = state.app.isOnline ? await effects.storage.storeUserDetails(user) : user;
  console.log(user);
  callback();
};

const logout: AsyncAction<void, any> = async ({ state, effects }) => {
  await effects.auth.doLogout();
  // state.vessels = {
  //   ...state.vessels,
  //   selectedVesselId: null,
  //   selectedVessel: null,
  //   userVesselData: null,
  // };
  // state.vessels.selectedVesselId = null;
  // state.vessels.userVesselData = null;
  state.auth.currentUser = null;
};

export { login, logout, clearError };
