import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Switch,
  FormControl,
  FormHelperText,
  DialogActions,
  FormControlLabel,
} from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import { useState } from '../../overmind';
import { TNiches, TActions, TRecordBookEntry, PortDetail } from '../../overmind/state';
import PortSelector from '../../components/PortSelector';
import MultiSelector from '../../components/MultiSelector';
import { Selector2 } from '../../components/Selector';
import VsDatePicker from './VsDatePicker';
import {
  fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselActions as VesselActions,
  fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselsBMRecords as VesselsBMRecords,
  fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselActions_VesselNiche_NicheAreaDefinition as NicheAreaDefinition,
} from '../../overmind/effects/gql/graphql-types/fetchUserData';
import { useStyles } from './styles';

const GreenCheckbox = withStyles({
  root: {
    'color': green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

type TRecordBookEntryFormInputs = {
  cleaningInspectionDate: Date;
  location: string;
  isAdHoc: boolean;
  managementAction: number;
  vesselNiches: number[];
  description: string;
  responsiblePerson: string;
  terms: boolean;
};

export type TNewEntryFormProps = {
  records: TRecordBookEntry[];
  editRecord?: TRecordBookEntry;
  onSubmit: (data: any) => void;
  onCancel: () => void;
};

/**
 * Return all adhoc (contingency) based niches minus the niches that are already planned.
 *
 * @param {number} actionId
 * @param {TNiches} niches
 * @param {(VesselActions[] | undefined)} plannedActions
 * @return {*}  {TNiches}
 */
const removePlannedNiches = (
  actionId: number,
  niches: TNiches,
  plannedActions: VesselActions[] | undefined,
): TNiches => {
  if (!plannedActions) return niches;

  const plannedNicheSet = new Set<number>();
  plannedActions
    .filter((action) => action.VesselsBMRecordType?.Id === actionId)
    .map((action) => plannedNicheSet.add(action.VesselNiche?.NicheAreaDefinition?.Id as number));
  const reducedNiches = niches.filter((niche) => !plannedNicheSet.has(niche.Id as number));
  return reducedNiches;
};

const getPortDetail = (portCode: string | undefined, ports: PortDetail[]): PortDetail | undefined => {
  if (portCode && ports.length > 0) {
    return ports.find((port) => port.PortCode === portCode);
  }
  return undefined;
};

const NewEntryForm = (props: TNewEntryFormProps) => {
  const { onSubmit, onCancel, records, editRecord } = props;
  const {
    register,
    handleSubmit,
    control,
    trigger,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { isDirty, errors }, //: { errors, isValid, isDirty, isSubmitting, touched, submitCount },
  } = useForm({
    mode: 'onChange',
  });
  const { selectedVessel, selectedVesselActionNiches } = useState().vessels;
  const actionNiches = useState().actionNiches;
  const ports = useState().ports;
  const classes = useStyles();

  /** triggered is used to coordinate errors between two realated fields: cleaningInspectionDate and managementAction */
  const triggered = React.useRef<boolean>(false);

  const [selectedAction, setSelectedAction] = React.useState<number | string>(editRecord?.managementActionId || '');
  const [selectedNiches, setSelectedNiches] = React.useState<TNiches>(editRecord?.vesselNiches || []);
  const [isAdHoc, setActionType] = React.useState<boolean>(editRecord?.isAdHoc || false);
  const [actionsSet, setActionsSet] = React.useState<Set<number>>(new Set());
  const [managementActions, setManagementActions] = React.useState<TActions>([]);
  const [vesselNiches, setVesselNiches] = React.useState<TNiches>([]);
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);
  const [recordErrorMessage, setRecordErrorMessage] = React.useState('');

  useEffect(() => {
    const plannedActions = new Set<number>();
    selectedVessel?.VesselParticular.VesselsBMRecords.map((action) => {
      if (action?.VesselAfsMaintenanceActionRecords.length > 0) {
        plannedActions.add(
          action.VesselAfsMaintenanceActionRecords[0].VesselAfsMaintenanceAction.VesselsBMRecordType?.Id!,
        );
      }
    });
    setActionsSet(plannedActions);
    return () => {};
  }, [selectedVessel]);

  useEffect(() => {
    if (isDirty) {
      if (isAdHoc) {
        clearErrors(['cleaningInspectionDate', 'managementAction']);
      } else {
        triggered.current = true;
        trigger(['cleaningInspectionDate', 'managementAction']);
      }
    }
    return () => {};
  }, [isAdHoc]);

  useEffect(() => {
    /**
     * Get all management actions that have already been planned for the currently selected vessel
     *
     * @return {*}  {TActions}
     */
    const vesselPlannedActions = (): TActions => {
      let actions: TActions = [];

      const actionSet = new Set<number>();
      selectedVessel?.VesselParticular.VesselActions.forEach((action) => {
        if (!action.IsAdHoc) {
          const actionId = action.VesselsBMRecordType?.Id as number;
          const { Id, MainNicheArea, NicheArea } = action.VesselNiche?.NicheAreaDefinition as NicheAreaDefinition;

          if (actionSet.has(actionId)) {
            const index = actions.findIndex((a) => a.value === actionId);
            actions[index].niches.push({
              Id,
              MainNicheArea,
              NicheArea,
            });
          } else {
            actions.push({
              value: action.VesselsBMRecordType?.Id,
              label: action.VesselsBMRecordType?.TypeDescription,
              niches: [
                {
                  Id,
                  MainNicheArea,
                  NicheArea,
                },
              ],
            });
          }

          actionSet.add(action.VesselsBMRecordType?.Id as number);
        }
      });

      console.log({ actions });
      return actions;
    };

    /**
     * Get a list of all possible management actions associated with the vessel that can be
     * applied in an adhoc manner.
     *
     * @return {*}  {TActions}
     */
    const vesselContingencyActions = (): TActions => {
      let actions: TActions = [];
      for (const action in selectedVesselActionNiches) {
        actions.push({
          value: parseInt(action),
          label: selectedVesselActionNiches[action].action?.TypeDescription,
          niches: selectedVesselActionNiches[action].niches.map((niche) => ({
            Id: niche.Id,
            MainNicheArea: niche.MainNicheArea,
            NicheArea: niche.NicheArea,
          })),
        });
      }

      return actions;
    };

    const actions = isAdHoc ? vesselContingencyActions() : vesselPlannedActions();

    actions?.unshift({ value: '', label: 'Select Management action...', niches: [] });

    setVesselNiches([]);
    setManagementActions(actions);
  }, [isAdHoc]);

  useEffect(() => {
    /**
     * Find all niches that the selected management action can be applied to.
     *
     * @param {(number | string)} action
     * @return {*}  {TNiches}
     */
    const getVesselNiches = (action: number | string): TNiches => {
      if (typeof action === 'string') return [];

      const index = managementActions.findIndex((a) => a.value === action);
      let niches = index >= 0 ? managementActions[index].niches : [];

      if (isAdHoc) {
        // Contingency
        // Need to remove planned action niches from list
        niches = removePlannedNiches(action, niches, selectedVessel?.VesselParticular.VesselActions);
      }
      return niches;
    };

    console.log('Loading vessel niche list');
    const niches = getVesselNiches(selectedAction);
    setVesselNiches(niches);
    // setSelectedNiches([]);
    if (editRecord?.managementActionId === selectedAction) {
      setValue('vesselNiches', editRecord.vesselNiches);
    } else {
      setValue('vesselNiches', []);
    }
  }, [selectedAction, managementActions]);

  const hasConflict = (actionDate: Date, action: number | string): [boolean, boolean] => {
    if (typeof action === 'string') return [false, false];
    if (editRecord) return [false, false];
    const theDate = new Date(actionDate).toDateString();
    const found = records.find((record) => {
      const existingEntryDate = new Date(record.cleaningInspectionDate).toDateString();
      return existingEntryDate === theDate && actionNiches[action].action?.TypeDescription! === record.managementAction;
    });
    return [!!found, found?.newRecord || false];
  };

  console.log(`Setting management action: ${selectedAction}`);
  return (
    <div className="NewEntryForm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Controller
              name="cleaningInspectionDate"
              control={control}
              defaultValue={editRecord ? new Date(editRecord.cleaningInspectionDate) : Date.now()}
              rules={{
                required: 'you must enter a valid date',
                validate: {
                  recordBookConflict: (value: any) => {
                    console.log({ actionsSet });
                    const managementAction = getValues('managementAction');
                    const [conflict, isNewRecord] = hasConflict(value, managementAction);
                    // if (isAdHoc) return true;
                    if (triggered.current) {
                      triggered.current = false;
                      return !conflict;
                    }
                    if (conflict) {
                      triggered.current = true;
                      setError('cleaningInspectionDate', {
                        type: 'cleaningInspectionDate',
                        message: isNewRecord
                          ? 'An un-synced offline record book entry exists for the chosen date and management action, please update the existing un-synced entry'
                          : 'A synced record book entry exists for the chosen date and management action, please update the existing entry using Vessel-Check',
                      });
                      trigger('managementAction');
                    } else {
                      clearErrors('managementAction');
                    }
                    return !conflict;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl error={!!errors.cleaningInspectionDate}>
                  <VsDatePicker
                    label="Date"
                    format="dd/MM/yyyy"
                    value={value}
                    error={!!errors.cleaningInspectionDate}
                    onChange={(date: Date) => {
                      onChange(date);
                    }}
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              id="action_date_picker_id"
              error={!!errors.cleaningInspectionDate}
              style={{ marginLeft: '14px', marginRight: '14px' }}
            >
              {errors.cleaningInspectionDate && recordErrorMessage}
              {/* {errors.cleaningInspectionDate && errors.cleaningInspectionDate.message} */}
              {/* {errors.cleaningInspectionDate &&
                errors.cleaningInspectionDate.type === 'required' &&
                errors.cleaningInspectionDate.message}
              {errors.cleaningInspectionDate &&
                errors.cleaningInspectionDate.type === 'recordBookConflict' && (
                  <>You have chosen a date and action that conflicts with an existing recordbook entry</>
                )} */}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={8}>
            <PortSelector
              name="location"
              label="Vessel Niche Selector"
              control={control}
              getValues={getValues}
              setValue={setValue}
              rules={{ required: 'you must enter a location' }}
              options={ports}
              defaultValue={getPortDetail(editRecord?.location, ports)}
            />

            <ErrorMessage
              errors={errors}
              name="location"
              as={
                <FormHelperText
                  id="location_selector_id"
                  error={!!errors.location}
                  style={{ marginLeft: '14px', marginRight: '14px' }}
                >
                  {errors.location?.message}
                </FormHelperText>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <Typography component="div">
                  <Grid component="label" direction="column" container alignItems="center" spacing={1}>
                    <Grid item>Contingency</Grid>

                    <Grid item>
                      <Controller
                        name="contingency"
                        control={control}
                        defaultValue={editRecord?.isAdHoc || false}
                        render={({ field: { onChange, value } }) => (
                          <FormControl variant="outlined">
                            <Switch
                              disabled={!!editRecord}
                              checked={value}
                              onChange={(e) => {
                                setActionType(e.target.checked);
                                onChange(e.target.checked);
                              }}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Selector2
              name="managementAction"
              label="Management action to apply"
              control={control}
              error={!!errors.managementAction}
              rules={{
                required: 'you must select a management action.',
                validate: {
                  recordBookConflict: (value: any) => {
                    console.log({ actionsSet });
                    const { cleaningInspectionDate } = getValues();
                    const [conflict, isNewRecord] = hasConflict(cleaningInspectionDate, value);
                    // if (isAdHoc) return true;
                    if (triggered.current) {
                      triggered.current = false;
                      return !conflict;
                    }
                    if (conflict) {
                      triggered.current = true;
                      setRecordErrorMessage(
                        isNewRecord
                          ? 'An un-synced offline record book entry exists for the chosen date and management action, please update the existing un-synced entry'
                          : 'A synced record book entry exists for the chosen date and management action, please update the existing entry using Vessel-Check',
                      );
                      setError('managementAction', {
                        type: 'managementAction',
                        message: isNewRecord
                          ? 'An un-synced offline record book entry exists for the chosen date and management action, please update the existing un-synced entry'
                          : 'A synced record book entry exists for the chosen date and management action, please update the existing entry using Vessel-Check',
                      });
                      trigger('cleaningInspectionDate');
                    } else {
                      clearErrors('cleaningInspectionDate');
                    }
                    return !conflict;
                  },
                },
              }}
              handleChange={setSelectedAction}
              defaultValue={selectedAction}
              variant="outlined"
              fullWidth
            >
              {managementActions.map((option) => (
                <MenuItem key={`ac_${option.value}`} value={option.value}>
                  <Typography className={classes.actionLabel} variant="subtitle1">
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </Selector2>

            <FormHelperText
              id="vessel-action-selector-Id"
              error={!editRecord && !!errors.managementAction}
              style={{ marginLeft: '14px', marginRight: '14px' }}
            >
              {errors.managementAction && recordErrorMessage}
              {errors.managementAction && errors.managementAction.message}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={6}>
            <MultiSelector
              name="vesselNiches"
              label="Vessel Niche Selector"
              control={control}
              getValues={getValues}
              setValue={setValue}
              rules={{ required: 'you must select a vessel niche' }}
              defaultValue={selectedNiches}
            >
              {vesselNiches.map((option) => (
                <MenuItem key={`vn_${option.Id}`} value={option as any}>
                  <Typography component="div">
                    <Typography variant="subtitle1" color="primary">
                      {option?.MainNicheArea}
                    </Typography>
                    <Typography variant="subtitle2" color="secondary">
                      {option?.NicheArea}
                    </Typography>
                  </Typography>
                </MenuItem>
              ))}
            </MultiSelector>
            <ErrorMessage
              errors={errors}
              name="vesselNiches"
              as={
                <FormHelperText
                  id="vessel-niche-selector-Id"
                  error={!!errors.vesselNiches}
                  style={{ marginLeft: '14px', marginRight: '14px' }}
                >
                  {errors.vesselNiches && errors.vesselNiches.type === 'required' && (
                    <>{errors.vesselNiches?.message?.message}</>
                  )}
                </FormHelperText>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="description"
              defaultValue={editRecord?.description}
              render={({ field }) => (
                <TextField
                  multiline
                  {...field}
                  fullWidth
                  rowsMax={5}
                  variant="outlined"
                  label="Management Description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Person Responsible"
              defaultValue={editRecord?.responsiblePerson}
              error={!!errors.responsiblePerson}
              helperText={errors.responsiblePerson?.message}
              {...register('responsiblePerson', { required: 'Required field as you are personally accountable' })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!errors.terms}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="terms"
                    defaultValue={acceptTerms}
                    rules={{ required: 'Accept terms & conditions' }}
                    render={({ field: { onChange, value } }) => (
                      <GreenCheckbox checked={value} onChange={(e) => onChange(e.target.checked)} />
                    )}
                  />
                }
                label="I confirm that the information is correct"
              />
              {!!errors.terms && <FormHelperText>{errors.terms?.message}</FormHelperText>}
            </FormControl>
          </Grid>
          <DialogActions style={{ width: '100%', flex: 'auto' }}>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
          {/* <Box p={2} display="flex" justifyContent="flex-end">
          </Box> */}
          {/* <Grid item xs={12}>
            <Grid container justify="space-evenly" alignItems="center" direction="row" spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <Button className={`DialogFormButton`} color="secondary" variant="outlined" onClick={onCancel}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <Button className={`DialogFormButton`} type="submit" color="primary" variant="contained">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default NewEntryForm;
