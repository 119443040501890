import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { green, red } from '@material-ui/core/colors';

import { fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselAfs as VesselAfs } from '../../overmind/effects/gql/graphql-types/fetchUserData';
import { formatDate } from '../../utils';
import { useState } from '../../overmind';
import AfcCoatingInfoCard from './AfcCoatingInfoCard';
import AfcNicheList from './AfcNicheList';
import AfcDocsList from './AfcDocsList';

interface IRecordBookProps {
  vessel?: any;
  location?: any;
}

export default function AntiFouling(_props: IRecordBookProps) {
  const { selectedVessel } = useState().vessels;

  const StatusIcon = ({ date, life }: { date: any; life: number }) => {
    const t = new Date(date);
    const today = new Date();

    t.setFullYear(t.getFullYear() + life);

    return today < t ? (
      <CheckCircleIcon style={{ color: green[500] }} />
    ) : (
      <ReportProblemIcon style={{ color: red[500] }} />
    );
  };

  const AfBodyd = () => {
    if (selectedVessel) {
      const rows = selectedVessel.VesselParticular.VesselAfs.map((afs: VesselAfs, index: number) => {
        return (
          <TableRow key={index}>
            <TableCell>
              <AfcCoatingInfoCard coating={afs.AFCoating} />
            </TableCell>
            <TableCell>{formatDate(afs.AfcDateApplied)}</TableCell>
            <TableCell>{`${afs.ExpectedLifeInYears} years`}</TableCell>
            <TableCell>
              <StatusIcon date={afs.AfcDateApplied} life={afs.ExpectedLifeInYears} />
            </TableCell>
            <TableCell>
              <AfcNicheList niches={afs.VesselAfsNiches} />
            </TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                {`(${afs.VesselAfsDocuments.length})`}
                <AfcDocsList documents={afs.VesselAfsDocuments} />
              </Box>
            </TableCell>
          </TableRow>
        );
      });

      return <>{rows}</>;
    } else {
      return <p>No selected vessel</p>;
    }
  };

  return selectedVessel ? (
    <Box px={4} py={2} style={{ marginTop: '215px', minWidth: '840px' }}>
      <Paper>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Application date</TableCell>
              <TableCell>Expected life</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Niches</TableCell>
              <TableCell>Documentation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <AfBodyd />
          </TableBody>
        </Table>
      </Paper>
    </Box>
  ) : (
    <div>loading</div>
  );
}
