import { throwError, of, firstValueFrom } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import bcrypt from 'bcryptjs';
import jwt_decode from 'jwt-decode';

import { storage } from '../storage';
import { TUser } from '../../state';
import InvalidAuthException from './authException';

const auth = {
  async doLogin(email: string, password: string, isOnline: boolean = true) {
    let payload = {
      email,
      password,
    };
    let user: Promise<TUser>;

    //TODO: login procedure depends on network status.
    // If offline, then if IndexedDB store:
    //   - is valid, load user and all information form there.
    //   - not valid and not online => redirect to unable to login details page
    //   - not valid and online => simply preform normal online operation
    // If online, then if IndexedDb store:
    //   - is valid, login and update sync status (awaiting user input to start sync operation)
    //   - is not valid => login

    if (!isOnline) {
      if (storage.checkUserStore(email)) {
        const hash = await storage.getUserHash(email);
        const isValid = hash && bcrypt.compareSync(password, hash);
        if (isValid) {
          return storage.getUser(email);
        }
        throw new InvalidAuthException(
          'Invalid User/Password',
          'Note: User access is limited to cached users only while in offline mode',
        );
      } else {
        throw new InvalidAuthException(
          'Invalid User/Password',
          'Note: User access is limited to cached users only while in offline mode',
        );
      }
    }
    console.log('Effects doLogin');
    user = firstValueFrom(
      ajax({
        url: `${process.env.REACT_APP_WEBAPI_URI}/Login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload,
      }).pipe(
        map((res: any) => {
          console.log('Ajax response: ', res);
          const decoded: any = jwt_decode(res.response.data.token);
          console.dir(decoded);

          return {
            user: {
              expires_in: res.response.data.expires_in,
              scope: res.response.data.scope,
              token: res.response.data.token,
              token_type: res.response.data.token_type,
              user: res.response.data.user,
            },
            userId: decoded.sub,
            status: 0, // not used
          };
        }),
        catchError((error: any) => {
          // console.log('Error: ', error);
          return throwError(new InvalidAuthException(error.response.title, error.response.detail));
        }),
      ),
    );

    return user || null;
  },

  doLogout() {
    // TODO: persist data store: user, queries, mutations
    console.log('logging out');

    return new Promise((resolve) => setTimeout(resolve, 500));
  },
};

export { auth };
