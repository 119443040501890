import React from 'react';
import { IconProps } from '@material-ui/core/Icon';
import { CloudOffTwoTone, CloudDoneTwoTone, CloudTwoTone } from '@material-ui/icons';
import { useState } from '../../overmind';

type Color = 'inherit' | 'primary' | 'secondary' | 'action' | 'error' | 'disabled';
type StatusIconProps = {
  status: string;
  sync?: boolean;
  onlineColor?: Color;
  offlineColor?: Color;
};

const NetworkStatusIcon = ({
  status,
  sync = false,
  onlineColor = 'inherit',
  offlineColor = 'inherit',
  ...props
}: StatusIconProps) => {
  switch (status.toLowerCase()) {
    case 'offline':
      return <CloudOffTwoTone {...props} color={offlineColor} />;
    case 'online':
      return sync ? <CloudDoneTwoTone {...props} /> : <CloudTwoTone {...props} color={onlineColor} />;
    default:
      return null;
  }
};

const NetworkStatusBadge = ({
  onlineColor,
  offlineColor,
  ...props
}: { onlineColor?: Color; offlineColor?: Color } & IconProps) => {
  const {
    app: { networkStatus, isSync },
  } = useState();

  return (
    <NetworkStatusIcon
      status={networkStatus}
      sync={isSync}
      onlineColor={onlineColor}
      offlineColor={offlineColor}
      {...props}
    />
  );
};

export default NetworkStatusBadge;
