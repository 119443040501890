import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useActions } from '../../overmind';

const Logout = () => {
  const {
    auth: { logout },
    vesselInfo: { setCurrentVessel, clearCurrentUserVesselData },
  } = useActions();

  useEffect(() => {
    const persistAndLogout = async () => {
      await logout();
    };
    setCurrentVessel(null);
    clearCurrentUserVesselData();
    persistAndLogout();

    return () => {};
  }, []);

  // TODO:
  // Quick hack until I rearrange routing - remove <Login> parents
  // see App/index.tsx where Login component is configured as a auth guard,
  // except that it does not prevent direct routees from being explicitly
  // entered into the adderess bar.

  return <Redirect to="/ " />;
};
export default Logout;
