import React, { useEffect } from 'react';
import { finalize, tap } from 'rxjs/operators';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircle from '@material-ui/icons/CheckCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { green } from '@material-ui/core/colors';

import QuickSnack from '../../components/QuickSnack';
import { useBlobServices } from '../BlobUploadContext';
import { BlobFileDownload, BlobFileUpload } from '../../overmind/effects/storage/Azure/blob-storage';

const isBlobFileUpload = (blob: BlobFileUpload | BlobFileDownload): blob is BlobFileUpload =>
  (blob as BlobFileUpload).progress ? true : false;

const FileUploadMenuItem = ({
  notification,
  showBorder,
}: {
  notification: BlobFileUpload | BlobFileDownload;
  showBorder: boolean;
}) => {
  return (
    <>
      {isBlobFileUpload(notification) ? (
        <MenuItem>
          <Box minWidth="16rem" display="flex" flexDirection="column">
            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
              <Typography style={{ marginRight: '0.5rem', maxWidth: '14rem' }} variant="body2" noWrap>
                {notification.filename}
              </Typography>
              {notification.progress === 100 ? (
                <CheckCircle style={{ color: green[500] }} fontSize="small" />
              ) : (
                <Typography variant="caption">{notification.progress}%</Typography>
              )}
            </Box>
            {notification.progress < 100 ? (
              <Box mt={1}>
                <LinearProgress value={notification.progress} variant="determinate" />
              </Box>
            ) : null}
          </Box>
        </MenuItem>
      ) : (
        <MenuItem>
          <Box minWidth="16rem" display="flex" flexDirection="column">
            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
              <Typography style={{ marginRight: '0.5rem', maxWidth: '14rem' }} variant="body2" noWrap>
                {notification.filename}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      )}
    </>
  );
};

const NotificationsDropdown = () => {
  const { uploadService, downloadService } = useBlobServices();
  const [notifications, setNotifications] = React.useState<BlobFileUpload[] | BlobFileDownload[]>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackMessage, setSnackMessage] = React.useState<string | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSnackClose = () => {
    setSnackMessage(null);
  }

  useEffect(() => {
    const subToUploads = uploadService.uploadedFiles.pipe(tap((files) => setNotifications(files))).subscribe();
    const subToDownloads = downloadService.downloadedFiles.subscribe(
      { next: d => {
          console.log('Azure blob data: ', d);
          const last = d[d.length -1];
          if (last.filename === 'Error') {
            setSnackMessage(last.containerName);
          }
        }, 
        error: err => {
          console.log("Severe Azure blob download error: ")
          console.error(err.message)
      }}
    );

    return () => {
      subToUploads.unsubscribe();
      subToDownloads.unsubscribe();
    };
  }, []);

  const badgeColor = notifications.length > 0 ? 'secondary' : 'primary';

  return (
    <>
            <QuickSnack message={snackMessage} open={snackMessage !== null} onClose={onSnackClose} />

      <IconButton aria-label="show notifications" color="inherit" onClick={handleClick}>
        <Badge badgeContent={notifications.length} color={badgeColor}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      {/* { open ? <MyMenu /> : null} */}

      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem disabled>Recent Notifications</MenuItem>

        {notifications.map((notification, index) => (
          <FileUploadMenuItem
            key={notification.filename}
            notification={notification}
            showBorder={index < notifications.length}
          />
        ))}
      </Menu>
    </>
  );
};

export default NotificationsDropdown;
