import { TAppState } from './state';

export const onInitialize = async ({ state, effects, actions }: { state: TAppState; effects: any; actions: any }) => {
  effects.gql.initialize({
    endpoint: process.env.REACT_APP_GQL_ENDPOINT,
    // This runs on every request
    headers: () => {
      return {
        Authorization: `Bearer ${state.auth.currentUser!.user.token}`,
        // 'x-hasura-admin-secret': 'hasuraSecretDevop',
      };
    },
    // The options are the options passed to GRAPHQL-REQUEST
    options: {
      credentials: 'include',
      mode: 'cors',
    },
  });
  console.log('Initializing network status handler');
  effects.network.initialize(actions.network.onNetworkStatusChange);

  console.log('Initializing storage');
  effects.storage.initialize();
};
