import React from 'react';
import 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const VsDatePicker = (props: any) => {
  const { label, inputRef, name, format, variant, error, value } = props;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(value || new Date());
  const onChange = (e: Date | null) => {
    setSelectedDate(e);
    props.onChange(e);
  };
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          label={label}
          name={name}
          format={format}
          error={error}
          inputVariant="outlined"
          value={selectedDate}
          onChange={onChange}
          inputRef={inputRef}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default VsDatePicker;
