import React from 'react';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { amber, green, red, grey, blueGrey, indigo } from '@material-ui/core/colors';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import TimeIcon from '@material-ui/icons/AccessTime';
import PersonIcon from '@material-ui/icons/Person';
import LocationIcon from '@material-ui/icons/Room';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

import { formatDate } from '../../utils';
import { TRecordBookEntry } from '../../overmind/state';
import OfflineSyncIcon from '../../components/SyncIcon';
import { useBlobServices } from '../BlobUploadContext';
import { useState } from '../../overmind';
import { fetchUserData_User_VesselParticularsUsers_VesselParticular_VesselsBMRecords_VesselBMRecordDocuments as VesselBMRecordDocuments } from '../../overmind/effects/gql/graphql-types/fetchUserData';

const InfoChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      marginRight: '0.5rem',
      marginBottom: '0.5rem',
      fontWeight: 'bolder',
      background: grey[100],
      border: 0,
    },
    icon: {
      color: theme.palette.primary.main,
    },
  }),
)(Chip);

const NicheChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      padding: '1.5rem 0.5rem',
      borderRadius: 4,
      background: grey[100],
      border: 0,
    },
  }),
)(Chip);

const DocumentChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      padding: '0.25rem',
      borderRadius: 4,
      fontWeight: 500,
    },
    deleteIcon: {
      width: '1.25rem',
    },
  }),
)(Chip);

const ContingencyIcon = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      background: indigo[300],
      fontWeight: 'bold',
      color: '#fff !important',
    },
  }),
)(Avatar);

const PlannedIcon = withStyles((theme: Theme) =>
  createStyles({
    root: {
      background: blueGrey[300],
      fontWeight: 'bold',
      color: '#fff !important',
    },
  }),
)(Avatar);

const WarningChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      border: `2px solid ${amber[700]}`,
      marginRight: '0.5rem',
      marginBottom: '0.5rem',
      fontWeight: 'bolder',
      background: grey[100],
    },
    icon: {
      color: amber[700],
    },
  }),
)(Chip);

const DuplicatesAccordion = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'marginTop': 0,
      'boxShadow': 'none',
      'borderTop': `1px solid ${grey[300]}`,
      'margin': '0 !important',
      '&:before': {
        content: 'none !important',
      },
    },
    expanded: {
      '&:last-child': {
        marginTop: 0,
      },
      '&:before': {
        content: 'none !important',
      },
      'margin': 0,
    },
  }),
)(Accordion);

const DuplicatesSummary = withStyles((theme: Theme) =>
  createStyles({
    root: {
      background: grey[50],
    },
  }),
)(AccordionSummary);

const DuplicatesDetails = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem',
      paddingBottom: 0,
      background: grey[50],
      borderTop: `1px solid ${grey[300]}`,
    },
  }),
)(AccordionDetails);

const primaryColor = '#3E5371';

const Document = ({
  record,
  document,
  handleOnDeleteAttachment,
  handleOnDownloadAttachment,
  isDeleteable,
}: {
  record: TRecordBookEntry;
  document: VesselBMRecordDocuments;
  isDeleteable: boolean;
  handleOnDeleteAttachment: (document: any) => void;
  handleOnDownloadAttachment: (document: any) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDocumentDelete = () => {
    handleOnDeleteAttachment(document);
  };

  const handleDocumentDownload = () => {
    handleOnDownloadAttachment(document);
  };

  return (
    <>
      <DocumentChip
        variant="outlined"
        label={
          <Box display="flex" alignItems="center">
            <Typography variant="body2">{document.Document.Name}</Typography>
            <Typography variant="caption" style={{ marginLeft: '0.25rem' }}>
              ({(document.Document.Size / 1000000).toFixed(3)}Mb)
            </Typography>
          </Box>
        }
        deleteIcon={<MoreVertIcon style={{ color: grey[600] }} fontSize="small" />}
        onDelete={handleClick}
        icon={<DescriptionIcon style={{ color: grey[500] }} fontSize="small" />}
      />
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {!record.newRecord && (
          <MenuItem onClick={handleDocumentDownload}>
            <CloudDownloadIcon style={{ marginRight: '0.5rem', color: primaryColor }} fontSize="small" />
            <Typography>Download</Typography>
          </MenuItem>
        )}
        {record.newRecord && (
          <MenuItem disabled={!isDeleteable} onClick={handleDocumentDelete}>
            <DeleteIcon style={{ marginRight: '0.5rem', color: red[600] }} fontSize="small" />
            <Typography>Delete</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const RecordBookEntryCard = ({
  record,
  isConflict,
  duplicates,
  isOnline,
  onEdit,
  onDelete,
  onAttach,
  onSync,
  onDeleteAttachment,
  onDownloadAttachment,
}: {
  record: TRecordBookEntry;
  duplicates: TRecordBookEntry[];
  isConflict: boolean;
  isOnline: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onAttach?: () => void;
  onSync?: () => void;
  onDeleteAttachment?: (document: any) => void;
  onDownloadAttachment?: (document: any) => void;
}) => {
  const { downloadService } = useBlobServices();
  const { currentUser } = useState().auth;
  const isDisabled = !record.newRecord || record.synced === 'pending';

  const handleOnDeleteAttachment = (document: any) => {
    if (!onDeleteAttachment) return;

    onDeleteAttachment(document.Document.Id);
  };

  const handleDownloadAttachment = (document: any) => {
    downloadService.AccessToken = currentUser!.user.token;
    try {
      downloadService.downloadFile(`${document.Document.Id}/${document.Document.Name}`);
    } catch (error) {
      alert((error as Error).message);
    }
  };

  let paperStyle: any = { background: record.synced === 'synced' ? 'white' : grey[200] };
  if (record.managementAction.startsWith('Invalid')) {
    paperStyle = {
      ...paperStyle,
      borderColor: red[300],
      borderWidth: '2px',
    };
  }

  return (
    <Box width="100%" mb={2}>
      <Paper elevation={0} variant="outlined" style={paperStyle}>
        <Box p={2} mr="auto" ml={0}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h5">{record.managementAction}</Typography>
              <Typography variant="body1">{record.description}</Typography>
            </Box>
            <OfflineSyncIcon disabled={!record.newRecord} isSync={record.synced} />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" mt={1} mr="auto" ml={0} flexWrap="wrap">
              <InfoChip
                size="small"
                icon={<TimeIcon fontSize="small" />}
                label={`Date: ${formatDate(record.cleaningInspectionDate)}`}
                variant="outlined"
              />
              <InfoChip
                size="small"
                icon={<LocationIcon fontSize="small" />}
                label={`Location: ${record.location}`}
                variant="outlined"
              />
              <InfoChip
                size="small"
                icon={<PersonIcon fontSize="small" />}
                label={`Recorded By: ${record.responsiblePerson}`}
                variant="outlined"
              />
            </Box>
            {record.documents.length === 0 && (
              <WarningChip
                size="small"
                icon={<WarningTwoToneIcon fontSize="small" />}
                label="No Attachements"
                variant="outlined"
              />
            )}
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {record.vesselNiches.map((niche) => (
              <NicheChip
                key={niche.Id}
                variant="outlined"
                avatar={
                  record.isAdHoc ? (
                    <ContingencyIcon variant="rounded">C</ContingencyIcon>
                  ) : (
                    <PlannedIcon variant="rounded">P</PlannedIcon>
                  )
                }
                label={
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">{niche.MainNicheArea}</Typography>
                    <Typography variant="caption">{niche.NicheArea}</Typography>
                  </Box>
                }
              />
            ))}
          </Box>
        </Box>
        {isConflict && duplicates.length > 0 ? (
          <DuplicatesAccordion TransitionProps={{ unmountOnExit: true }}>
            <DuplicatesSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <ErrorIcon style={{ color: red[600], marginRight: '0.5rem' }} />
                <Typography variant="subtitle1">
                  <b>Duplicate Records Detected</b> (Resolve in the main Vessel Check application)
                </Typography>
              </Box>
            </DuplicatesSummary>
            <DuplicatesDetails>
              {duplicates.map((rec) => (
                <RecordBookEntryCard
                  key={rec.id!}
                  record={rec}
                  duplicates={[]}
                  isConflict={false}
                  isOnline={isOnline}
                />
              ))}
            </DuplicatesDetails>
          </DuplicatesAccordion>
        ) : null}
        {record.documents && record.documents.length > 0 ? (
          <Box p={2} pt={0}>
            <Box display="flex" alignItems="center" mb={0.5}>
              <AttachmentIcon style={{ marginRight: '0.5rem', color: grey[400] }} />
              <Typography variant="subtitle1">Attachments</Typography>
            </Box>
            {record.documents.map((document) => (
              <Document
                key={document.Document.Id}
                record={record}
                document={document}
                handleOnDeleteAttachment={handleOnDeleteAttachment}
                handleOnDownloadAttachment={handleDownloadAttachment}
                isDeleteable={record.newRecord && record.synced !== 'pending'}
              />
            ))}
          </Box>
        ) : null}
        {isDisabled ? null : (
          <Box borderTop={`1px solid ${grey[300]}`}>
            <ButtonGroup variant="text" fullWidth>
              <Button startIcon={<EditIcon style={{ color: primaryColor }} fontSize="small" />} onClick={onEdit}>
                Edit
              </Button>
              <Button startIcon={<AttachmentIcon style={{ color: grey[400] }} fontSize="small" />} onClick={onAttach}>
                Attach
              </Button>
              <Button
                disabled={!isOnline}
                startIcon={
                  isOnline ? (
                    <SyncIcon style={{ marginRight: '0.5rem', color: green[400] }} />
                  ) : (
                    <SyncDisabledIcon style={{ marginRight: '0.5rem', color: grey[400] }} />
                  )
                }
                onClick={onSync}
              >
                Sync
              </Button>
              <Button
                startIcon={<DeleteIcon style={{ color: red[400] }} fontSize="small" />}
                onClick={onDelete ? () => onDelete() : () => {}}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default RecordBookEntryCard;
