import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import ExitToAppTwoTone from '@material-ui/icons/ExitToAppTwoTone';

import { useState } from '../../overmind';
import NetworkStatusBadge from './NetworkStatusBadge';
import NotificationsDropdown from './NotificationsDropdown';
import InstallPWAButton from '../../components/InstallPWAButton';
import { getDecodedDeets } from '../../overmind/utils';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    statusBadge: {
      margin: theme.spacing(2),
    },
    title: {
      display: 'none',
      color: 'inherit',
      [theme.breakpoints.up('xs')]: {
        display: 'block',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    nonMenuItem: {
      borderBottom: '1px gray solid',
    },
    logout: {
      justifyContent: 'space-around',
    },
  }),
);

export default function Topbar() {
  const classes = useStyles();
  const { isLoggedIn, currentUser } = useState().auth;
  const [userDetails, setUserDetails] = React.useState<any | undefined>(undefined);
  // const { blobUploadService } = useState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [uploadedFiles, setUploadedFiles] = React.useState<BlobFileUpload[]>([]);
  // const [uploadError, setUploadError] = React.useState<string>('');

  const history = useHistory();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (currentUser) setUserDetails(getDecodedDeets(currentUser.user.token));
  }, [currentUser]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    history.push('/logout');
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const getUploadedFiles = () => {
  //   console.log('NavBar has subscribed to blobUploadService !');
  //   // const sub = blobUploadService!.uploadedFiles.pipe(tap((res) => setUploadedFiles(res))).subscribe();
  //   const sub = blobUploadService!.uploadedFiles.subscribe({
  //     next: (results) => {
  //       setUploadedFiles(results);
  //     },
  //     error: (error) => setUploadError(error.message),
  //     complete: () => console.log('Upload completed'),
  //   });
  //   return () => sub.unsubscribe();
  // };

  // useEffect(getUploadedFiles, []);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled onClick={handleProfileMenuOpen} className={classes.nonMenuItem}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Typography component="div">
          <Typography variant="h6" component="div">
            {userDetails?.name}
          </Typography>
          <Typography variant="button" component="div">
            {userDetails?.orgName}
          </Typography>
          <Typography variant="body1" component="div">
            {currentUser?.user.user}
          </Typography>
        </Typography>
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>Settings</MenuItem> */}
      <MenuItem onClick={handleLogout} className={classes.logout}>
        Logout
        <IconButton
          aria-label="logout of application"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppTwoTone />
        </IconButton>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem disabled onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{currentUser?.user.user}</p>
      </MenuItem>

      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="settings of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <SettingsTwoToneIcon />
        </IconButton>
        <p>Settings</p>
      </MenuItem> */}

      <MenuItem onClick={handleLogout}>
        <IconButton
          aria-label="logout of application"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppTwoTone />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer">
            <img src="/vesselcheck-white-logo.png" alt="Vesselcheck Logo" />
          </IconButton>
          <div className={classes.sectionMobile}>
            <Typography className={classes.title} variant="h6">
              ORB
            </Typography>
          </div>
          <div className={classes.sectionDesktop}>
            <Typography className={classes.title} variant="h6">
              Vessel-Check Offline Record Book
            </Typography>
          </div>

          <div className={classes.grow} />
          <div>
            <InstallPWAButton />
          </div>
          <div className={classes.statusBadge}>
            <NetworkStatusBadge fontSize="large" offlineColor="secondary" />
          </div>
          {isLoggedIn && (
            <div className={classes.sectionDesktop}>
              <NotificationsDropdown />

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          )}
          {isLoggedIn && (
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {isLoggedIn && (
        <>
          {renderMobileMenu}
          {renderMenu}
        </>
      )}
    </div>
  );
}

// import React from 'react';
// import { useState } from '../../overmind/index';

// import NetworkStatusBadge from './NetworkStatusBadge';
// import SyncStatusBadge from './SyncStatusBadge';

// const Topbar = () => {
//   const { currentUser } = useState().auth;
//   const { networkStatus } = useState().app;

//   return (
//     <div className={'TopBar ' + networkStatus.toLocaleLowerCase()}>
//       <div className="TopBarInner">
//         <div className="appLogo">
//           <img src="/vesselcheck-white-logo.png" />
//         </div>
//         <div className="appName">Vessel-Check Offline Record Book</div>

//         <div className="appStatus">
//           <NetworkStatusBadge />
//           <SyncStatusBadge />
//         </div>

//         <div className="TopBarSpacer"></div>
//         <div className="appUser">{currentUser?.data?.user}</div>
//       </div>
//     </div>
//   );
// };

// export default Topbar;
