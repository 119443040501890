import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

import { useState, useActions } from '../../../overmind';
import { useBlobServices } from '../../BlobUploadContext';

const VESSEL_IMAGE_URL = process.env.REACT_APP_VESSEL_IMAGES_URL;

const useStyles = makeStyles({
  bold: {
    fontWeight: 600,
  },
  fieldValue: {
    marginLeft: '5px',
  },
  cardContent: {
    marginTop: '5px',
  },
  inactive: {
    color: '#e9b117',
  },
});
interface IVesselCardProps {
  vessel: any;
}

const VesselCard = (props: IVesselCardProps) => {
  const { vessel } = props;
  const { VesselParticular } = vessel;
  const history = useHistory();
  const { currentUser } = useState().auth;
  const {
    vesselInfo: { setCurrentVessel },
  } = useActions();
  const { downloadService } = useBlobServices();
  const [vesselImage, setVesselImage] = React.useState(`${VESSEL_IMAGE_URL}/${vessel.VesselId}/display.jpg`);
  const [noImage, setNoImage] = React.useState('');
  const classes = useStyles();

  useEffect(() => {
    console.log(`----> Mounting card for vessel: ${VesselParticular.VesselName}`);
    fetch(vesselImage, {
      method: 'HEAD',
      headers: {
        Accept: '*/*',
      },
    })
      .then((res) => {
        if (!res.ok) {
          setVesselImage('/no-image.jpg');
          setNoImage('?ni');
        } else {
          setNoImage('');
        }
      })
      .catch((error) => console.log(error));

    return () => console.log(`----> Unmounting card for vessel: ${VesselParticular.VesselName}`);
  }, []);

  const handleClick = (event: any) => {
    event.preventDefault();
    setCurrentVessel(vessel.VesselId);
    history.push(`/recordbook/${vessel.VesselId}${noImage}`);
  };

  const handleVesselSubscribe = (event: any) => {
    event.preventDefault();
  };

  const handleBMPDownload = (event: any) => {
    event.preventDefault();
    downloadService.AccessToken = currentUser!.user.token;
    const bmpDocument = VesselParticular.VesselBMPs[0]?.VesselBMPDocuments[0]?.Document;
    const bmpFilename = `${bmpDocument.Id}/${bmpDocument.Name}`;
    downloadService.downloadFile(bmpFilename);
  };

  const isSubscribed = VesselParticular.Subscriptions.length > 0;
  const isActive = isSubscribed && VesselParticular.Subscriptions[0]?.Status === 'Active';

  return (
    <Card>
      <CardMedia title={vessel.Name} image={vesselImage} style={{ height: '15rem' }} />
      <CardContent>
        <Typography variant="h6" component="h2">
          {VesselParticular.VesselName}
        </Typography>
        <Box display="flex" flexDirection="column" className={classes.cardContent}>
          <div style={{ display: 'flex' }}>
            <Typography className={classes.bold}>IMO number: </Typography>
            <Typography className={classes.fieldValue}>{VesselParticular.IMONumber}</Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography className={classes.bold}>Call sign: </Typography>
            <Typography className={classes.fieldValue}>{VesselParticular.CallSign}</Typography>
          </div>

          <div style={{ display: 'flex' }}>
            <Typography className={classes.bold}>Subscription(s): </Typography>
            {isSubscribed ? (
              <Typography className={classes.fieldValue}>{VesselParticular.Subscriptions[0]?.Name}</Typography>
            ) : (
              <Typography color="textSecondary" className={classes.fieldValue}>
                None
              </Typography>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <Typography className={classes.bold}>Status: </Typography>
            {isSubscribed ? (
              <Typography className={classes.fieldValue}>{VesselParticular.Subscriptions[0]?.Status}</Typography>
            ) : (
              <Typography color="textSecondary" className={classes.fieldValue}>
                None
              </Typography>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {isSubscribed ? (
              <>
                <Typography className={classes.bold}>Expires on: </Typography>
                <Typography className={classes.fieldValue}>{VesselParticular.Subscriptions[0]?.EndsAt}</Typography>
              </>
            ) : (
              <br />
            )}
          </div>
        </Box>
      </CardContent>
      <CardActions>
        <Box display="flex" flexDirection="column" width="100%">
          <Button
            variant="outlined"
            disabled={!isSubscribed}
            color="primary"
            style={{ marginBottom: '0.5rem' }}
            onClick={handleBMPDownload}
          >
            Vessel BMP
          </Button>
          {isSubscribed ? (
            <Button
              variant={isActive ? 'contained' : 'outlined'}
              color={isActive ? 'primary' : 'secondary'}
              onClick={handleClick}
              style={{ width: '100%' }}
            >
              Record Book
            </Button>
          ) : (
            <Button variant="contained" color="secondary" onClick={handleVesselSubscribe} style={{ width: '100%' }}>
              Subscribe
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default VesselCard;
