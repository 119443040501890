import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ServiceWorkerWrapper from './App/ServiceWorkerWrapper';
import App from '../src/App/index';

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerWrapper />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
