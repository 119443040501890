import React, { useEffect } from 'react';

import { parseISO, format } from 'date-fns';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel, { TableSortLabelProps } from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import Swal from 'sweetalert2';
import { FilePondFile } from 'filepond';
import { amber, green, indigo, blueGrey } from '@material-ui/core/colors';

import NewEntryDialog from './NewEntryDialog';
import { useState, useActions } from '../../overmind';
import { TFileAttachment, TRecordBookEntry } from '../../overmind/state';
import { Func } from '../../utils';
import { useStyles } from './styles';
import FileUploader from '../../components/FileUploader';

import RecordBookEntriesList from './RecordBookEntriesList';
import { useBlobServices } from '../BlobUploadContext';
import { tap } from 'rxjs/operators';
import { BlobFileUpload } from '../../overmind/effects/storage/Azure/blob-storage';
import { storage } from '../../overmind/effects/storage';

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'color': '#fff !important',
      '&:hover': {
        color: '#fff !important',
      },
      '&:focus': {
        color: '#fff !important',
      },
    },
    active: {
      'color': '#fff !important',
      '&:hover': {
        color: '#fff !important',
      },
      '&:focus': {
        color: '#fff !important',
      },
    },
    icon: {
      color: '#fff !important',
    },
  }),
)(TableSortLabel);

export const useRecordBook = () => {
  // const [records, setRecords] = React.useState<Array<TRecordBookEntry>>([]);
  const [editRecordEntry, setEditRecordEntry] = React.useState<TRecordBookEntry | undefined>(undefined);
  const { selectedVessel, selectedVesselId } = useState().vessels;
  const { iDb, recordBookEntries, selectedVesselRecordBookEntries: records } = useState();
  const { currentUser } = useState().auth;
  const { uploadService } = useBlobServices();
  const {
    vesselRecords: {
      deleteRecordBookEntryAndAttachements,
      sortRecordBookEntries,
      transferRecordBookEntries,
      syncRecordBookEntries,
      saveDocuments,
      deleteDocument,
    },
  } = useActions();

  const deleteRecord = (value: TRecordBookEntry | number) => {
    console.log(`Deleting record: ${JSON.stringify(value)}`);
    //console.dir(records[index]);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecordBookEntryAndAttachements(value).then((res) => {
          Swal.fire('Deleted!', 'Your Record Entry has been deleted.', 'success');
        });
        // TODO: handle error
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire('Cancelled', 'Your Record Entry has not been deleted', 'error');
      }
    });
  };

  const orderRecords = (compareFn: (a: any, b: any) => number) => sortRecordBookEntries(compareFn);

  const editRecord = (value: TRecordBookEntry | number) => {
    // alert(`edit record ${JSON.stringify(value, null, 2)}`);
    setEditRecordEntry(value as TRecordBookEntry);
  };

  const syncAllRecords = async () => {
    console.log(`Sync'ing ${recordBookEntries.length} records`);
    if (recordBookEntries.length === 0) {
      return;
    }
    const txId = await transferRecordBookEntries(recordBookEntries);
    if (!txId) {
      // Handle error
      console.log('Synchronization failed.');
      return;
    }
    // This will need to be moved and called after all associated documents have been uploaded
    // Also add error checks (could be null)
    const effectedRows = await syncRecordBookEntries(txId.insert_offline_SyncTransaction_one!.Id);
    // alert(`sync records: Tx ID = ${txId}`);
    await uploadRecordAttachements(recordBookEntries);
  };

  const syncRecord = async (recordBookEntry: TRecordBookEntry) => {
    const txId = await transferRecordBookEntries([recordBookEntry]);
    // alert(`sync records: Tx ID = ${txId}`);
    await uploadRecordAttachements([recordBookEntry]);
    const effectedRows = await syncRecordBookEntries(txId.insert_offline_SyncTransaction_one!.Id);
    console.log(`Synchronised ${effectedRows} record: Tx ID = ${txId.insert_offline_SyncTransaction_one!.Id}`);
  };

  const saveAttachments = (record: TRecordBookEntry | null, files: FilePondFile[]) => {
    saveDocuments({ record, files });
    // alert('open attachments');
  };

  const onDeleteAttachment = (record: TRecordBookEntry, id: string) => {
    console.log({ record, id });
    deleteDocument({ record, id });
  };

  const uploadRecordAttachements = async (rbes: TRecordBookEntry[]) => {
    console.log('uploadRecordAttachements');
    for (let rbe of rbes) {
      const files: TFileAttachment[] = [];

      // for (let i = 0; i < rbes.length; i++) {
      //   console.log(`Loading indexedDb file: ${rbe.documents[i].Document.Id}`);
      //   const file = await storage.getFileById(rbe.documents[i].Document.Id);
      //   console.dir(file);
      //   if (file) {
      //     files.push(file);
      //   }
      // }

      const indexDbFiles = rbe.documents.map((doc) => storage.getFileById(doc.Document.Id));
      for await (let file of indexDbFiles) {
        if (file) {
          files.push(file);
        }
      }
      console.log(`Resolved array length: ${files.length}`);
      uploadService.AccessToken = currentUser!.user.token;
      uploadService!.uploadFiles(files);
    }
  };

  return {
    models: {
      records,
      selectedVessel,
      selectedVesselId,
    },
    operations: {
      orderRecords,
      deleteRecord,
      editRecord,
      editRecordEntry,
      setEditRecordEntry,
      syncAllRecords,
      syncRecord,
      saveAttachments,
      onDeleteAttachment,
    },
  };
};

type ColumnType = {
  label: string;
  name: string;
  sortable: boolean;
  active: boolean;
  order: 'asc' | 'desc';
};

interface IRecordBookProps {
  vessel?: any;
  location?: any;
}

const ContingencyIcon = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      background: indigo[300],
      fontSize: '0.75em',
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
)(Avatar);

const PlannedIcon = withStyles((theme: Theme) =>
  createStyles({
    root: {
      background: blueGrey[300],
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: '0.75em',
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
)(Avatar);

export default function RecordBook(props: IRecordBookProps) {
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [fileUploaderIsOpen, setFileUploaderIsOpen] = React.useState(false);
  const [currentRecord, setCurrentRecord] = React.useState<TRecordBookEntry | null>(null);
  const { isOnline } = useState().app;
  const {
    models: { records },
    operations: {
      orderRecords,
      deleteRecord,
      editRecord,
      editRecordEntry,
      setEditRecordEntry,
      syncAllRecords,
      syncRecord,
      saveAttachments,
      onDeleteAttachment,
    },
  } = useRecordBook();

  const classes = useStyles();

  const [columns, setColumns] = React.useState<ColumnType[]>([
    { label: '', name: 'expander', sortable: false, active: false, order: 'asc' },
    { label: 'Date', name: 'cleaningInspectionDate', sortable: true, active: false, order: 'asc' },
    { label: 'Action Type', name: 'managementAction', sortable: false, active: false, order: 'asc' },
    { label: 'Location', name: 'location', sortable: true, active: false, order: 'asc' },
    { label: 'Description', name: 'description', sortable: false, active: false, order: 'asc' },
    { label: 'Status', name: 'synced', sortable: false, active: false, order: 'asc' },
    { label: 'Added By', name: 'responsiblePerson', sortable: true, active: false, order: 'asc' },
  ]);

  const handleSortClick = (index: number) => () => {
    setColumns(
      columns.map((column, i) => ({
        ...column,
        active: index === i,
        order: (index === i && (column.order === 'desc' ? 'asc' : 'desc')) || 'asc',
      })),
    );

    orderRecords(comparator(columns[index].name, columns[index].order === 'desc'));
  };

  const onAttach = (value: TRecordBookEntry) => {
    setCurrentRecord(value);
    setFileUploaderIsOpen(true);
  };

  const closeFileUploader = () => {
    setFileUploaderIsOpen(false);
  };

  const saveFileUploaderFiles = (files: FilePondFile[]) => {
    console.log(`Attaching ${files.length} files to record book entry: ${currentRecord?.managementAction}`);
    console.dir(files);

    files.map(async (file: FilePondFile) => {
      const byteArray = await file.file.arrayBuffer();

      console.log(`
        Name: ${file.filename}
        Size: ${file.fileSize}
        Type: ${file.fileType}
        Extn: ${file.fileExtension}
        Meta: ${JSON.stringify(file.getMetadata(), null, 2)}
        File: ${byteArray.byteLength}
      `);
    });

    setFileUploaderIsOpen(false);
    saveAttachments(currentRecord, files);
  };

  useEffect(() => {
    if (editRecordEntry) setDialogIsOpen(!dialogIsOpen);
  }, [editRecordEntry]);

  useEffect(() => {
    if (!dialogIsOpen) setEditRecordEntry(undefined);
  }, [dialogIsOpen]);

  return (
    <Box px={0} py={2} style={{ marginTop: '215px' }}>
      <Paper className={classes.paper}>
        <Box p={2} className={classes.paperContent}>
          <FileUploader open={fileUploaderIsOpen} onClose={closeFileUploader} onOK={saveFileUploaderFiles} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
            ml={0}
            className={classes.readOnly}
          >
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" mr={1}>
                <ContingencyIcon variant="rounded">C</ContingencyIcon>
                <Typography variant="body2" style={{ marginLeft: '0.25rem' }}>
                  {'- Contingency'}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" ml={1}>
                <PlannedIcon variant="rounded">P</PlannedIcon>
                <Typography variant="body2" style={{ marginLeft: '0.25rem' }}>
                  {'- Planned'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="outlined"
                onClick={syncAllRecords}
                style={{ marginRight: '0.5rem' }}
                disabled={!isOnline}
                startIcon={isOnline ? <SyncIcon /> : <SyncDisabledIcon />}
              >
                Sync
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => {
                  setDialogIsOpen(!dialogIsOpen);
                }}
              >
                ADD RECORD
              </Button>
            </Box>
          </Box>
          <Box className={classes.panelRecords}>
            <NewEntryDialog
              records={records}
              editRecord={editRecordEntry}
              open={dialogIsOpen}
              setOpen={setDialogIsOpen}
            />
            <RecordBookEntriesList
              records={records}
              isOnline={isOnline}
              onSync={syncRecord}
              onDelete={deleteRecord}
              onDeleteAttachment={onDeleteAttachment}
              onEdit={editRecord}
              onAttach={onAttach}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

/**
 * Fairly generic comparator for most simple types.
 *
 * @param {string} prop
 * @param {boolean} [desc=true]
 */
const comparator =
  (prop: string, desc: boolean = true) =>
  (a: any, b: any) => {
    const order = desc ? -1 : 1;
    if (a[prop] < b[prop]) {
      return -1 * order;
    }

    if (a[prop] > b[prop]) {
      return 1 * order;
    }
    return 0 * order;
  };

const FLabel = (props: TableSortLabelProps) => <>{props.children}</>;
const sortableColumn =
  ({ sortable, ...props }: TableSortLabelProps & { sortable: boolean }) =>
  (node: JSX.Element) =>
    sortable ? <StyledTableSortLabel {...props}>{node}</StyledTableSortLabel> : node;

const Sortable = Func.ap(sortableColumn)(FLabel);
