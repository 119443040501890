import { fetchUserData_ActionsNiches as ActionsNiches } from './effects/gql/graphql-types/fetchUserData';
import { ActionNiches, NicheActions, TRecordBookEntry } from './state';
import { fetchUserData_User_VesselParticularsUsers as VesselParticularsUsers } from './effects/gql/graphql-types/fetchUserData';
import jwtDecode from 'jwt-decode';

const countryToFlag = (PortCode: string) => {
  const isoCode = PortCode.substr(0, 2);
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

const flattenBy = (table: Array<ActionsNiches>) => (field: 'action' | 'niche') => {
  switch (field) {
    case 'action': {
      const actionNiches: Record<string, ActionNiches> = {};
      table.map((el) => {
        if (!actionNiches.hasOwnProperty(el.VesselsBMRecordType.Id)) {
          actionNiches[el.VesselsBMRecordType.Id] = new ActionNiches(el.VesselsBMRecordType, []);
        }
        actionNiches[el.VesselsBMRecordType.Id].niches.push(el.NicheAreaDefinition);
      });
      return actionNiches;
    }
    case 'niche': {
      const nicheActions: Record<string, NicheActions> = {};
      table.map((el) => {
        if (!nicheActions[el.NicheAreaDefinition.Id]) {
          nicheActions[el.NicheAreaDefinition.Id] = new NicheActions(el.NicheAreaDefinition, []);
        }
        nicheActions[el.NicheAreaDefinition.Id].actions.push(el.VesselsBMRecordType);
      });
      return nicheActions;
    }
  }
};

const getCurrentVesselRecords = (selectedVessel: VesselParticularsUsers): TRecordBookEntry[] => {
  if (!selectedVessel) return [];
  const selectedVesselId = selectedVessel.VesselId;
  const vesselBmRecords = selectedVessel.VesselParticular.VesselsBMRecords ?? [];
  const recordEntries: TRecordBookEntry[] = vesselBmRecords.map((entry) => {
    const actions = entry.VesselAfsMaintenanceActionRecords;
    const niches = actions.map((action) => ({
      ...action.VesselAfsMaintenanceAction.VesselNiche!.NicheAreaDefinition!,
      // isAdHoc: action.VesselAfsMaintenanceAction.IsAdHoc,
    }));

    return {
      id: entry.Id,
      vesselId: selectedVesselId,
      newRecord: false,
      synced: 'synced',
      txId: null, // TODO: set correctly
      cleaningInspectionDate: new Date(entry.CleaningInspectionDate),
      location: entry.Location ?? 'unknown',
      isAdHoc: actions.length > 0 ? actions[0].VesselAfsMaintenanceAction.IsAdHoc : false,
      description: entry.ManagementActions,
      conflictId: entry.ConflictId,
      deletedAt: entry.DeletedAt,
      responsiblePerson: entry.ResponsiblePerson ?? 'unknown',
      managementActionId: actions.length > 0 ? actions[0].VesselAfsMaintenanceAction.VesselsBMRecordType?.Id! : 0,
      managementAction:
        actions.length > 0
          ? actions[0].VesselAfsMaintenanceAction.VesselsBMRecordType?.TypeDescription!
          : 'Invalid Record book entry',
      vesselNiches: niches || [],
      documents: entry.VesselBMRecordDocuments || [],
      lastUpdatedAt: null,
      deleted: false,
    };
  });

  return recordEntries;
};

const removePendingRecords = (vcRecords: TRecordBookEntry[], rbes: TRecordBookEntry[]) => {
  const pendingRbes = rbes.filter((rbe) => rbe.synced === 'pending');
  // Now look for record in fetched vessel check records and delete from rbes if found
  // (since recordbook entry has been sync'ed with server)
  pendingRbes.forEach((rbe) => {
    const record = vcRecords.findIndex(
      (v) =>
        v.managementActionId === rbe.managementActionId &&
        v.cleaningInspectionDate.toLocaleDateString() === new Date(rbe.cleaningInspectionDate).toLocaleDateString() &&
        v.isAdHoc === rbe.isAdHoc &&
        v.synced === 'synced',
    );
    if (record > 0) {
      console.log('found record');
      const index = rbes.indexOf(rbe);
      // rbes.splice(index, 1);
      return rbes.slice(0, index).concat(rbes.slice(-index));
    }
  });
  return rbes;
};

const getDecodedDeets = (token: string, key?: string | number | undefined): any => {
  const decoded: any = jwtDecode(token);
  const deets = {
    name: decoded.name,
    email: decoded.emails[0],
    uid: decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'],
    role: decoded['https://hasura.io/jwt/claims']['x-hasura-role'],
    orgId: decoded['https://hasura.io/jwt/claims']['x-hasura-user-org-id'],
    orgName: decoded['https://hasura.io/jwt/claims']['x-hasura-user-org-name'],
    orgTypeId: decoded['https://hasura.io/jwt/claims']['x-hasura-user-org-type-id'],
    orgTypeName: decoded['https://hasura.io/jwt/claims']['x-hasura-user-org-type-name'],
  };

  if (!key || key === undefined) {
    return deets;
  }

  if (key && typeof key === 'string') {
    return deets[key as keyof typeof deets];
  }

  return deets;
};

export { countryToFlag, flattenBy, getCurrentVesselRecords, removePendingRecords, getDecodedDeets };
