class InvalidAuthException extends Error {
  title: string = 'Invalid User/Password';
  detail: string = '';

  constructor(title?: string, detail?: string) {
    super(title);
    title && (this.title = title);
    detail && (this.detail = detail);
  }
}

export default InvalidAuthException;
