import DbManager from './DBManager';
import { TAppState, TFileAttachment, TRecordBookEntry, TUser } from '../../state';

let iDbStorage: DbManager;

const storage = {
  initialize(): void {
    iDbStorage = new DbManager();
  },

  storeUserDetails(currentUser: TUser): Promise<TUser> {
    return iDbStorage.storeUser(currentUser);
  },

  checkUserStore(email: string): boolean {
    return iDbStorage.checkUser(email);
  },

  persistState(appState: TAppState): void {
    iDbStorage.storePorts(appState.ports);
    // iDbStorage.storePorts2(appState.ports);
    iDbStorage.storeActionsNiches(appState.mgmtActionsNicheAreas);
    iDbStorage.storeVessels(appState.vessels.userVesselData!);
  },

  getUser(email: string) {
    return iDbStorage.getUser(email);
  },

  getUserHash(email: string) {
    return iDbStorage.getUserHash(email);
  },

  getUserVesselData(email: string) {
    return iDbStorage.getUserVessels(email);
  },

  getActionsNiches() {
    return iDbStorage.getActionsNiches();
  },

  getPorts() {
    return iDbStorage.getPorts();
  },

  saveRecords(appState: TAppState, records: TRecordBookEntry[]) {
    const email = appState.vessels.userVesselData!.Email!;
    return iDbStorage.storeRecordEntries(email, records);
  },

  getRecords(appState: TAppState) {
    const email = appState.vessels.userVesselData!.Email!;
    return iDbStorage.getRecordEntries(email);
  },

  saveFile(file: TFileAttachment) {
    return iDbStorage.storeFileAttachment(file);
  },

  getFileById(id: string) {
    return iDbStorage.getFileAttachmentById(id);
  },

  deleteFileById(id: string): Promise<void> {
    return iDbStorage.deleteFileAttachmentById(id);
  },

  deleteFileByName(filename: string): Promise<number> {
    return iDbStorage.deleteFileAttachmentByFilename(filename);
  },
};

export { storage };
