import { IConfig } from 'overmind';
import { createActionsHook, createEffectsHook, createHook, createStateHook } from 'overmind-react';
import { state } from './state';
import { onInitialize } from './onInitialize';
import * as actions from './actions';
import * as effects from './effects';

const config = {
  onInitialize,
  state,
  actions: { ...actions },
  effects: { ...effects },
};

declare module 'overmind' {
  interface Config extends IConfig<typeof config> {}
}

const useOvermind = createHook<typeof config>();
const useState = createStateHook<typeof config>();
const useActions = createActionsHook<typeof config>();
const useEffects = createEffectsHook<typeof config>();

export { config, useOvermind, useState, useActions, useEffects };
