import Dexie, { DexieOptions, Table } from 'dexie';
import { exists } from 'fs';

import { TFileAttachment, TRecordBookEntry, TUser, PortDetail } from '../../state';
import {
  fetchUserData_Port,
  fetchUserData_User,
  fetchUserData_ActionsNiches,
} from '../gql/graphql-types/fetchUserData';

const INDEXED_DB_NAME = 'vcOffline';

class DbManager extends Dexie {
  static VERSION_1_0 = 1;
  static PORTS_STORE = 'ports';
  static PORTS_STORE2 = 'ports2';
  static FILE_STORE = 'files';

  static ACTION_NICHES_STORE = 'actions_niches';

  dbName: string;
  currentSchema: any;

  constructor(dbName: string = INDEXED_DB_NAME, options?: DexieOptions) {
    super(dbName);
    // setup some shared stores
    this.dbName = dbName;
    this.currentSchema = {
      ports: '',
      actions_niches: '',
      rbe: 'Id',
      files: 'id',
    };
    this.initDB();
  }

  async initDB() {
    if (!(await Dexie.exists(this.dbName))) {
      const dbVersion = this.verno === 0 ? DbManager.VERSION_1_0 : this.verno;
      this.version(dbVersion).stores(this.currentSchema);
    }
    this.open();
  }

  async extendSchema(schemaChanges: any) {
    this.close();

    // Appears we have to append and send whole schema definition for each version upgrade
    // and not just the deltas (changes)
    this.currentSchema = {
      ...this.currentSchema,
      ...schemaChanges,
    };
    // Tell Dexie about next schema:
    this.version(this.verno + 1).stores(schemaChanges);
    // Upgrade it:
    return await this.open();
  }

  // async createStores(): Promise<void> {
  //   if (!(await Dexie.exists(this.dbName))) {
  //     console.log(`Database "${this.dbName}" does not exist`);
  //     this.extendSchema({
  //       ports: '',
  //       // ports2: 'PortCode',
  //       actions_niches: '',
  //       rbe: 'Id',
  //       files: 'id',
  //     });
  //   } else {
  //     console.log(`Database "${this.dbName}" exists`);
  //   }
  // }

  getTable<T, IndexableType>(schema: string): Table<T, IndexableType> {
    return this.table(schema);
  }

  async storeUser(currentUser: TUser): Promise<TUser> {
    let userStore: Table<string> | null = null;

    try {
      userStore = this.getTable(currentUser.user.user);
    } catch (err) {
      if (!userStore) {
        await this.extendSchema({
          [currentUser.user.user]: '',
        });
        console.log(`DB version: ${this.verno}`);

        userStore = this.getTable(currentUser.user.user);
        await userStore.put(JSON.stringify(currentUser), 'user');
        return currentUser;
      }
      userStore = this.getTable(currentUser.user.user);
      if (!userStore) {
        throw new Error((err as Error).message);
      }
    }

    // add/overwrite with new information
    // const userStore: Table<string> = this.getTable(currentUser.user.user);

    return new Promise<TUser>((resolve, reject) => {
      userStore!
        .put(JSON.stringify(currentUser), 'user')
        .then((value) => resolve(currentUser))
        .catch((err) => reject(err));
    });
  }

  storePorts2(ports: fetchUserData_Port[]) {
    // 0: Proxy {PortCode: "AEABU", PortName: "Abu Al Bukhoosh", Symbol(CACHED_PROXY): Proxy, Symbol(CACHED_PROXY): Proxy}
    // 1: Proxy {PortCode: "AEAJM", PortName: "Ajman", Symbol(CACHED_PROXY): Proxy, Symbol(CACHED_PROXY): Proxy}

    type PortFields<T> = {
      [P in keyof T as Extract<P, 'PortCode' | 'PortName>'>]: T[P];
    };

    const tmp: PortFields<fetchUserData_Port> = ports.slice(0, 1)[0];
    console.log('PortFields extracted: ');
    console.dir(tmp as any);

    const portsStr = JSON.stringify(ports);
    const sanitizedPorts = JSON.parse(portsStr);
    const portStore: Table<string> = this.getTable(DbManager.PORTS_STORE2);
    portStore.clear();
    console.log('Storing ports...');
    portStore
      .bulkAdd(sanitizedPorts)
      .then((last) => console.log(`Done loading ${ports.length} ports`))
      .catch(Dexie.BulkError, (e) => console.error(`Ports bulk loading error: ${e.message}`))
      .catch(Dexie.DataCloneError, (e) => console.error(`Could not clone ports array: ${e.meesage}`));
  }

  async storePorts(ports: PortDetail[]) {
    const portStore: Table<string> = this.getTable(DbManager.PORTS_STORE);
    await portStore.clear();
    portStore.add(JSON.stringify(ports), 'ports');
  }

  async storeActionsNiches(actionsNiches: fetchUserData_ActionsNiches[]) {
    const actionNichesStore: Table<string> = this.getTable(DbManager.ACTION_NICHES_STORE);
    await actionNichesStore.clear();
    actionNichesStore.add(JSON.stringify(actionsNiches), 'actionNicheMap');
  }

  storeVessels(vesselData: fetchUserData_User) {
    const userTable: Table<string> = this.getTable(vesselData.Email!);

    userTable.put(vesselData.Id, 'id');
    userTable.put(vesselData.Name!, 'name');
    userTable.put(vesselData.Password!, 'pid');
    userTable.put(JSON.stringify(vesselData), 'vessels');
  }

  checkUser(email: string): boolean {
    try {
      const userStore: Table<string> = this.getTable(email);
      return !!userStore;
    } catch (err) {
      // console.log(err);
      return false;
    }
  }
  getUser(email: string): Promise<TUser | null> {
    const userStore: Table<string> = this.getTable(email);
    return userStore.get('user').then((value) => value && JSON.parse(value));
  }

  getUserHash(email: string): Promise<string | undefined> {
    const userStore: Table<string> = this.getTable(email);
    return userStore.get('pid').then((value) => value);
  }

  getPorts(): Promise<PortDetail[]> {
    const portTable: Table<string> = this.getTable(DbManager.PORTS_STORE);
    return portTable.get('ports').then((value) => value && JSON.parse(value));
  }

  getActionsNiches(): Promise<fetchUserData_ActionsNiches[]> {
    const table: Table<string> = this.getTable(DbManager.ACTION_NICHES_STORE);
    return table.get('actionNicheMap').then((value) => value && JSON.parse(value));
  }

  getUserVessels(email: string): Promise<fetchUserData_User> {
    const userStore: Table<string> = this.getTable(email);
    return userStore.get('vessels').then((value) => value && JSON.parse(value));
  }

  getRecordEntries(email: string): Promise<TRecordBookEntry[]> {
    const userStore: Table<string> = this.getTable(email);
    return userStore.get('recordBookEntries').then((value) => (value ? JSON.parse(value) : []));
  }
  storeRecordEntries(email: string, records: TRecordBookEntry[]): Promise<number> {
    const userStore: Table<string> = this.getTable(email);
    return userStore
      .put(JSON.stringify(records), 'recordBookEntries')
      .then((value) => {
        const n = records.length;
        console.log(`${n} ${n === 1 ? 'record' : 'records'} saved`);
        return n;
      })
      .catch((err) => {
        console.log(err);
        return 0;
      });
  }

  storeFileAttachment(file: TFileAttachment) {
    const table: Table<TFileAttachment> = this.getTable(DbManager.FILE_STORE);
    return table.add(file);
  }

  getFileAttachmentById(id: string) {
    const table: Table<TFileAttachment> = this.getTable(DbManager.FILE_STORE);
    return table.where('id').equals(id).first();
  }

  deleteFileAttachmentById(id: string) {
    const table: Table<TFileAttachment> = this.getTable(DbManager.FILE_STORE);
    return table.delete(id);
  }

  deleteFileAttachmentByFilename(file: string) {
    const table: Table<TFileAttachment> = this.getTable(DbManager.FILE_STORE);
    return table.where('filename').equals(file).delete();
  }
}

export default DbManager;
