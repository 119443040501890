import { NicheAreaDefinition } from './../../vcTypes';
import { AsyncAction, Action } from 'overmind';
import { TAppState, TRecordBookEntry, TVessels } from '../state';
import { fetchUserData } from '../effects/gql/graphql-types/fetchUserData';
import { countryToFlag } from '../utils';

const loadStoredRecordBookEntries = async ({ state, effects }: { state: TAppState; effects: any }) => {
  const rbes: TRecordBookEntry[] = await effects.storage.getRecords(state);
  state.recordBookEntries = rbes.filter((rec) => rec.synced !== 'pending');
};

const loadStoredUserVesselData = async ({ state, effects }: { state: TAppState; effects: any }) => {
  state.vessels.userVesselData = await effects.storage.getUserVesselData(state.auth.currentUser!.user.user);
  state.mgmtActionsNicheAreas = await effects.storage.getActionsNiches();
  state.ports = await effects.storage.getPorts();
};

const fetchUserVesselDataByQuery = async ({ state, effects }: { state: TAppState; effects: any }) => {
  const authorizedUser = state.auth?.currentUser?.user.user;
  const { User, Port, ActionsNiches } = (await effects.gql.queries.fetchUserData({
    email: authorizedUser,
    today: new Date().toISOString(),
  })) as fetchUserData;
  const user = User[0];
  console.dir(user);
  state.vessels.userVesselData = user;
  state.ports = Port.map(({ PortCode, PortName }) => ({
    CountryCode: countryToFlag(PortCode),
    PortCode,
    PortName,
  }));
  state.mgmtActionsNicheAreas = ActionsNiches;

  // console.log(user.selectedVessel?.VesselParticular.VesselBMPs[0].VesselBMPDocuments[0].Document.);
  await effects.storage.persistState(state);
};

const fetchUserVesselData: AsyncAction = async ({ state, effects }: { state: TAppState; effects: any }) => {
  state.app.isOnline
    ? await fetchUserVesselDataByQuery({ state, effects })
    : await loadStoredUserVesselData({ state, effects });
  await loadStoredRecordBookEntries({ state, effects });
};

const fetchOfflineRecordEntryData: AsyncAction<void, any> = async ({
  state,
  effects,
}: {
  state: TAppState;
  effects: any;
}) => {
  if (!state.app.isOnline) return;
  const authorizedUser = state.auth?.currentUser?.userId;
  const rbes = await effects.gql.queries.fetchOfflineRecordEntryData({ id: authorizedUser });
  console.log('fetchOfflineRecordEntryData');
  console.dir(rbes);
};

const setCurrentVessel: Action<number | null, any> = ({ state }: { state: TAppState }, vesselId) => {
  state.vessels.selectedVesselId = vesselId;
  console.log(`setCurrentVessel: ${state.vessels.selectedVesselId}`);
};

const clearCurrentUserVesselData: Action<void, any> = ({ state }: { state: TAppState }) => {
  state.vessels.userVesselData = null;
  console.log(`clearCurrentUserVesselData: ${state.vessels.userVesselData}`);
};

export { fetchUserVesselData, fetchOfflineRecordEntryData, setCurrentVessel, clearCurrentUserVesselData };
