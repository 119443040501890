import * as React from 'react';
import { FilePond, FilePondProps } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import 'filepond/dist/filepond.min.css';
import { styled } from '@material-ui/core/styles';
import { TRecordBookEntry } from '../overmind/state';

export type FileUploaderProps = FilePondProps & {
  open: boolean;
  fileTypes?: string[];
  onOK?: (files: any) => void;
  onClose?: () => void;
};

const FileUploader: React.FunctionComponent<FileUploaderProps> = ({ open, fileTypes, onOK, onClose }) => {
  const [files, setFiles] = React.useState<any>([]);

  React.useEffect(() => {
    setFiles([]);
  }, [open]);

  const handleOK = () => {
    if (!onOK) {
      console.error('TBI');
      return;
    }
    onOK(files);
  };
  const handleCancel = () => {
    if (!onClose) {
      console.log('TBI');
      return;
    }

    onClose();
  };

  const onUpdateFiles = (fileItems: any) => {
    console.log('filelist updated');
    setFiles(fileItems);
  };

  return (
    <Dialog onClose={handleCancel} maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography variant="h6">SELECT FILES TO ATTACH</Typography>
      </DialogTitle>
      <DialogContent>
        <$FilePond
          files={files}
          onupdatefiles={onUpdateFiles}
          allowMultiple={true}
          dropOnPage
          name="files"
          dropValidation
          allowReorder={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleOK} color="primary" variant="contained">
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const $FilePond = styled(FilePond)({
  '.filepond--item': {
    width: 'calc(50% - .5em)',
  },
});

export default FileUploader;
