import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useActions, useState } from '../../overmind';
import NewEntryForm from './NewEntryForm';
import { TActions, TNiches, TRecordBookEntry, PortDetail } from '../../overmind/state';

type TRecordBookEntryFormInput = {
  contingency: boolean;
  cleaningInspectionDate: Date;
  location: PortDetail;
  description: string;
  responsiblePerson: string;
  managementAction: string;
  vesselNiches: TNiches;
};

type TNewEntryDialogProps = {
  records: TRecordBookEntry[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editRecord?: TRecordBookEntry;
};

const NewEntryDialog = (props: TNewEntryDialogProps) => {
  const { open, setOpen, records, editRecord } = props;
  const {
    actionNiches,
    nicheActions,
    vessels: { selectedVesselId },
  } = useState();
  const {
    vesselRecords: { addRecordBookEntry, deleteRecordBookEntry },
  } = useActions();
  const [dialogTitle, setDialogTitle] = React.useState<string>('');

  const onSubmit = async (data: TRecordBookEntryFormInput) => {
    // save to local store.
    const {
      contingency,
      cleaningInspectionDate,
      location,
      description,
      responsiblePerson,
      managementAction,
      vesselNiches,
    } = data;

    console.log(`${dialogTitle} recordbook entry: ${JSON.stringify(data)}`);

    // const niches = vesselNiches.map((niche) => ({
    //   ...nicheActions[niche as any].niche!,
    // }));

    const recordBookEntry: TRecordBookEntry = {
      id: null,
      vesselId: selectedVesselId,
      newRecord: true,
      synced: 'notsynced',
      txId: null,
      cleaningInspectionDate: cleaningInspectionDate,
      location: location.PortCode || '',
      isAdHoc: contingency,
      description,
      responsiblePerson,
      conflictId: null,
      deletedAt: null,
      managementActionId: actionNiches[managementAction!].action?.Id!,
      managementAction: actionNiches[managementAction!].action?.TypeDescription!,
      documents: [],
      vesselNiches,
      lastUpdatedAt: null,
      deleted: false,
    };

    if (editRecord) {
      recordBookEntry.documents = editRecord.documents;
      await deleteRecordBookEntry(editRecord);
      console.log('Updating record book:', recordBookEntry);
    }
    await addRecordBookEntry(recordBookEntry);
    setOpen(false);
  };

  const onCancel = () => {
    console.log(`Submit cancelled`);
    setOpen(false);
  };

  useEffect(() => {
    setDialogTitle(editRecord ? 'Edit' : 'New');
    return () => {};
  }, [editRecord]);

  return (
    <Dialog open={open} onClose={setOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" align="center">{`${dialogTitle} Record Book Entry`}</Typography>
      </DialogTitle>
      <DialogContent className="DialogContentRB" style={{ overflow: 'auto' }} dividers>
        <NewEntryForm records={records} onSubmit={onSubmit} onCancel={onCancel} editRecord={editRecord} />
      </DialogContent>
    </Dialog>
  );
};

export default NewEntryDialog;
