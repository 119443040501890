//
// Todo: Part way yo being a generic component. Need to remove dependency on NicheAreaDefinition
//
import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import HighlightOffTwoTone from '@material-ui/icons/HighlightOffTwoTone';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { Controller, UseControllerProps, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { fetchUserData_ActionsNiches_NicheAreaDefinition as NicheAreaDefinition } from '../overmind/effects/gql/graphql-types/fetchUserData';
import NicheChip from '../components/NicheChip';
import { getDefaultUserAgentValue } from '@azure/core-http';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginBottom: '3px',
      paddingTop: '2.0em',
      paddingBottom: '2.0em',
      borderRadius: '5px',
    },
    chipIcon: {
      paddingBottom: '5px',
    },
    chipLabel: {
      lineHeight: '1.0',
      fontWeight: 'bolder',
      fontSize: '0.7rem',
      paddingTop: '4px',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    chipSublabel: {
      lineHeight: '1',
      fontSize: '0.7rem',
    },
  }),
);

type OptionValue = Array<number | string>;
type TProps<T> = {
  label: string;
  handleChange?: (value: T[]) => void;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;

  children: React.ReactNode;
};

// const MultiSelector = () => {
//   return <div>MultiSelector</div>;
// };
type MultiSelectorProps<T> = TProps<T> & UseControllerProps;

const MultiSelector = <T,>({
  name,
  label,
  control,
  defaultValue,
  setValue,
  getValues,
  rules,
  handleChange,
  children,
  ...rest
}: MultiSelectorProps<T>) => {
  // const { name, label, control, defaultValue, children, } = props;
  const [selectValues, setSelectValues] = React.useState<NicheAreaDefinition[]>([]);
  const classes = useStyles();

  const labelId = `${name}-select-label`;

  const handleDelete = (niche: NicheAreaDefinition) => () => {
    console.log(`Removing niche (chip): ${JSON.stringify(niche)}`);
    let niches = getValues(name);
    niches = niches.filter((n: NicheAreaDefinition) => n.Id !== niche.Id);
    setValue(name, niches);
    // setSelectValues((chips) => chips?.filter((chip) => chip.Id !== niche.Id));
  };

  // useEffect(() => {
  //   console.log('List of options: ',optionsList);
  //   console.log(`Default niches: `, JSON.stringify(defaultValue));
  //   // const list: NicheAreaDefinition[] = [];
  //   // list.push(optionsList[0])
  //    setSelectValues(defaultValue);
  //   return () => {};
  // }, [optionsList]);

  // const chipRenderValue2 = (selected: unknown) => {
  //   return (
  //     <div>
  //       {(selected as Array<number | string>).map((v) => {
  //         if (typeof v === 'string') return null;
  //         const i = optionsList.find((option: any) => option.value === v);
  //         // Force i into a NicheAreaDefinition object required nby NicheChip niche prop
  //         const niche: NicheAreaDefinition = {
  //           Id: v,
  //           MainNicheArea: (i as any)?.label,
  //           NicheArea: (i as any)?.subLabel,
  //         };
  //         return (
  //           <Typography component="div">
  //             <NicheChip
  //               key={v}
  //               size="small"
  //               niche={niche}
  //               classes={classes}
  //               onDelete={handleDelete(niche)}
  //               variant="default"
  //               deleteIcon={<HighlightOffTwoTone className={classes.chipIcon} />}
  //               onMouseDown={(e) => {
  //                 e.stopPropagation();
  //               }}
  //             />
  //           </Typography>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const chipRenderValue = (selected: Array<NicheAreaDefinition>) => {
    return (
      <div>
        {selected.map((v) => {
          const niche = v;
          console.log(`Chip is rendering: ${v.Id}`);
          if (!v) return null;
          return (
            <Typography component="div">
              <NicheChip
                key={v.Id}
                size="small"
                niche={niche}
                classes={classes}
                onDelete={handleDelete(niche)}
                variant="default"
                deleteIcon={<HighlightOffTwoTone className={classes.chipIcon} />}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              />
            </Typography>
          );
        })}
      </div>
    );
  };

  return (
    <FormControl {...rest} variant="outlined" fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Select
            multiple
            labelId={labelId}
            label={label}
            value={value}
            renderValue={(selected) => {
              console.log('Select value: ', value);
              // onChange(selected);
              return chipRenderValue(selected as Array<NicheAreaDefinition>);
            }}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              console.log(`onChange`);
              console.dir(e.target);
              handleChange && handleChange(e.target.value as T[]);
              // setSelectValues(e.target.value as NicheAreaDefinition[]);
              onChange(e.target.value);
            }}
          >
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default MultiSelector;
