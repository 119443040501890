import { from, OperatorFunction, Subject } from 'rxjs';
import { map, mergeMap, scan, startWith, switchMap, tap } from 'rxjs/operators';

import { TFileAttachment } from '../../../state';
import { BlobContainerRequest, BlobFile, BlobFileUpload } from './blob-storage';
import { BlobStorageService } from './BlobStorage';

type Dictionary<T> = {
  [key: string]: T;
};

class BlobUploadService {
  accessToken: string | undefined;
  private uploadQ = new Subject<TFileAttachment[]>();

  uploadedFiles = this.uploadQueue.pipe(
    mergeMap((file) => this.uploadFile(file)),
    this.blobStorage.scanFiles(),
  );

  /**
   * The getter is listening on the queue (Subject) and will emit each file from
   * array of TFileAttachment's
   *
   * @readonly
   * @memberof BlobUploadService
   */
  get uploadQueue() {
    return this.uploadQ.asObservable().pipe(mergeMap((files) => from(files)));
  }

  set AccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  /**
   * Creates an instance of BlobUploadService.
   * @param {BlobStorageService} blobStorage
   * @memberof BlobUploadService
   */
  constructor(private blobStorage: BlobStorageService) {
    this.accessToken = undefined;
  }

  uploadFiles(files: TFileAttachment[]): void {
    if (!this.accessToken) {
      throw new Error('Invalid access token');
    }
    this.uploadQ.next(files);
  }

  private uploadFile = (file: TFileAttachment) =>
    this.blobStorage.getStorageAccessWithContainer(this.accessToken!).pipe(
      switchMap((res) =>
        this.blobStorage
          .uploadToBlobStorage(file, {
            ...res,
            filename: `${file.id}/${file.filename}`,
          })
          .pipe(this.mapUploadResponse(file, res)),
      ),
    );

  // Custom operator
  private mapUploadResponse = (
    file: TFileAttachment,
    options: BlobContainerRequest,
  ): OperatorFunction<number, BlobFileUpload> => (source) =>
    source.pipe(
      map((progress) => ({
        filename: file.filename,
        vesselid: file.vesselid,
        containerName: options.containerName,
        progress: parseInt(((progress / file.filesize) * 100).toString(), 10),
      })),
      startWith({
        filename: file.filename,
        vesselid: file.vesselid,
        containerName: options.containerName,
        progress: 0,
      }),
    );
}
export { BlobUploadService };
