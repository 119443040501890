import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      padding: theme.spacing(0.75),
    },
  }),
);
interface Props {}

const InstallPWAButton = (props: Props) => {
  const [offlineInstall, setOfflineInstall] = useState(null);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      console.log(e.platforms);
      setSupportsPWA(true);
      setOfflineInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (e: any) => {
    if (!offlineInstall) {
      return;
    }
    (offlineInstall! as any).prompt();
  };

  if (!supportsPWA) {
    return null;
  }

  return (
    <Button
      className={classes.button}
      variant="contained"
      color="default"
      size="small"
      onClick={onClick}
      startIcon={<GetApp />}
    >
      Install Offline
    </Button>
  );
};

export default InstallPWAButton;
